import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import useDistConfig from "../../../services/useDistConfig";
import WishToaster from "../../../components/WishToaster";

export default function PasswordReset() {
  const { changeDistPassword } = useDistConfig();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setError] = useState(null);
  const [payload, setPayload] = useState({
    distributor_id: "",
    user_type: 1,
    new_password: "",
    confirm_password: "",
  });

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Distributor Configuration", linkTo: "/distconfig" });
  breadcrumbs.push({ title: "Password Reset", linkTo: "/distpasswordreset" });

  const doChangePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (payload.new_password.trim() === "") {
      setError("Please provide current password");
      return;
    }
    if (payload.new_password !== payload.confirm_password) {
      setError("Passwords do not match!");
      return;
    }
    setLoading(true);
    changeDistPassword(payload, (response) => {
      setError(null);
      setLoading(false);
      setPayload({ distributor_id: "", user_type: 1, new_password: "", confirm_password: "" });
      WishToaster({
        toastMessage: "Password successfully updated",
        toastType: "success",
      });
    });
  };

  useEffect(() => {
    console.log(payload);
  }, [payload]);

  const body = function () {
    return (
      <>
        <WishSingleLineText
          label="Direct seller ID"
          id={"dist_id"}
          initialValue={payload.distributor_id}
          onChange={(newValue) =>
            setPayload({
              ...payload,
              distributor_id: newValue,
            })
          }
        />
        <WishSingleLineText
          label="New Password"
          id={"new_password"}
          initialValue={payload.new_password}
          onChange={(newValue) =>
            setPayload({
              ...payload,
              new_password: newValue,
            })
          }
        />
        <WishSingleLineText
          label="Confirm Password"
          id={"confirm_password"}
          initialValue={payload.confirm_password}
          onChange={(newValue) =>
            setPayload({
              ...payload,
              confirm_password: newValue,
            })
          }
          onBlurred={() => {
            if (payload.new_password !== payload.confirm_password) {
              setError("Passwords do not match!");
            } else {
              setError(null);
            }
          }}
        />
      </>
    );
  };

  const footer = function () {
    return (
      <>
        <button className="btn btn-primary" onClick={doChangePassword} disabled={loading}>
          {loading ? <div className="spinner-border text-light" role="status"></div> : null}
          Update
        </button>
        <p className="text-right text-danger">{errorMessage}</p>
      </>
    );
  };

  return (
    <PageLayout activeSideMenu="3" pageTitle="Password Reset" header="Password Reset" breadcrumbs={breadcrumbs}>
      <section className="row">
        <div className="col-12">
          <WishSimpleCard body={body()} footer={footer()}></WishSimpleCard>
        </div>
      </section>
    </PageLayout>
  );
}
