import WishSingleLineText from "../../../../components/WishFormComponents/WishSingleLineText";
import WishFileControl from "../../../../components/WishFormComponents/WishFileControl";
import useEnrollment from "../../../../services/useEnrollment";
import WishToaster from "../../../../components/WishToaster";

export default function BankDetailsIDTransfer({ IDTransferPayload, setIDTransferPayload }) {
  const { getBankBranchDetails } = useEnrollment();

  return (
    <div className="col-sm-12 col-md-12 col-lg-6 mt-1">
      <h5>Bank details</h5>
      <hr />

      <WishSingleLineText
        label="Account Holder"
        initialValue={IDTransferPayload?.bankdeatils?.account_name}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            bankdeatils: { ...IDTransferPayload.bankdeatils, account_name: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Account Number"
        initialValue={IDTransferPayload?.bankdeatils?.account_no}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            bankdeatils: { ...IDTransferPayload.bankdeatils, account_no: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="IFSC Code"
        initialValue={IDTransferPayload?.bankdeatils?.ifsc}
        onChange={(newValue) => {
          if (newValue.toUpperCase().replace(/\s/g, "").match("^[A-Z]{4}0[A-Z0-9]{6}$")) {
            getBankBranchDetails(newValue, (data) => {
              setIDTransferPayload({
                ...IDTransferPayload,
                bankdeatils: {
                  ...IDTransferPayload.bankdeatils,
                  bank_name: data.bank_name,
                  branch_name: data.branch_name,
                  ifsc: newValue.toUpperCase().replace(/\s/g, ""),
                },
              });
            });
          } else {
            WishToaster({
              toastMessage: "Enter a valid IFSC code!",
              toastType: "error",
            });
            setIDTransferPayload({
              ...IDTransferPayload,
              bankdeatils: { ...IDTransferPayload.bankdeatils, bank_name: "", branch_name: "", ifsc: newValue.toUpperCase().replace(/\s/g, "") },
            });
          }
        }}
      />
      <WishSingleLineText label="Bank Name" readonly initialValue={IDTransferPayload?.bankdeatils?.bank_name} />
      <WishSingleLineText label="Bank Branch" readonly initialValue={IDTransferPayload?.bankdeatils?.branch_name} />
      {/* <WishFileControl
        label="Bank (proof of address)"
        initialValue={IDTransferPayload?.bankdeatils?.bank_proof_file?.name}
        onChange={(fileName, fileObject) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            bankdeatils: { ...IDTransferPayload.bankdeatils, bank_proof_file: fileObject },
          });
        }}
      /> */}
    </div>
  );
}
