import useAPIs from "./useAPIs";

const useConfig = () => {
  const { postData, getData, postFormData } = useAPIs();

  const createRole = (payload, onSuccess, onError) => {
    postData(
      "/auth/create_role",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getUsersRoutesRoles = (payload, onSuccess, onError) => {
    postData(
      "/auth/get-roles-routes-users",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const assignRoutesToRole = (payload, onSuccess, onError) => {
    postData(
      "/auth/assign_role_and_route",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const assignRoleToUser = (payload, onSuccess, onError) => {
    postData(
      "/auth/assign_role",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const createUser = (payload, onSuccess, onError) => {
    postData(
      "/auth/create-bo-user",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  return {
    createRole,
    getUsersRoutesRoles,
    assignRoutesToRole,
    assignRoleToUser,
    createUser,
  };
};

export default useConfig;
