import { useEffect, useState } from "react";
import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "./AppUtils";

const useSupport = (distributorId) => {
  const { postData, getData, postFormData } = useAPIs();
  const loggedInUser = JSON.parse(localStorage.getItem("orgUser"));

  const urlList = [
    { key: 1, url: "/enrollment/bo/inbox" },
    { key: 2, url: "/enrollment/bo/sent-vmail" },
    { key: 3, url: "/enrollment/bo/vmail-details" },
    { key: 4, url: "/enrollment/bo/fetch-my-tickets" },
    { key: 5, url: "/enrollment/bo/fetch-ticket-details" },
    { key: 6, url: "/enrollment/bo/change-ticket-priority" },
    { key: 7, url: "/enrollment/bo/change-ticket-status" },
    { key: 8, url: "/enrollment/bo/change-ticket-assignee" },
    { key: 9, url: "/enrollment/bo/fetch-user-details" },
    { key: 10, url: "/enrollment/bo/new-mail-list" },
  ];
  const formDataURLList = [
    { key: 1, url: "/enrollment/bo/new-mail" },
    { key: 2, url: "/enrollment/bo/reply-mail" },
    { key: 3, url: "/enrollment/bo/add-ticket-comment" },
    { key: 4, url: "/enrollment/bo/change-ticket-status" },

  ];
  const postGeneral = (URLid, payload, onSuccess, onError) => {
    let URLtoCall = urlList.find((item) => item.key === URLid);
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    postData(
      URLtoCall?.url,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const getVmailMasters = (onSuccess, onError) => {
    getData(
      `/enrollment/bo/fetch-support-master-list`,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const postGeneralFormdata = (payload, URLid, onSuccess, onError) => {
    let URLtoCall = formDataURLList.find((item) => item.key === URLid);
    postFormData(
      URLtoCall?.url,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const changeStatus = (payload, flag, onSuccess, onError) => {
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    postData(
      flag ? "/enrollment/bo/change-vmail-read-status" : "/enrollment/bo/change-vmail-status",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const getOrgUsers = (payload, onSuccess, onError) => {
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    postData(
      "/enrollment/bo/orguser-search",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const getProfile = (payload, onSuccess, onError) => {
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    postData(
      `/enrollment/bo/fetch-dist-profile`,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const createTicket = (payload, onSuccess, onError) => {
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    let mpayload = new FormData();
    mpayload.append("orguser_id", payload?.orguser_id);
    mpayload.append("orguser_name", payload?.orguser_name);
    mpayload.append("parent_vmail_id", payload?.parent_vmail_id ? payload?.parent_vmail_id : null);
    mpayload.append("ticket_muser_type_id", payload?.ticket_muser_type_id);
    mpayload.append("distributor_id", payload?.distributor_id);
    mpayload.append("distributor_name", payload?.distributor_name);
    mpayload.append("customer_id", payload?.customer_id);
    mpayload.append("customer_phone", payload?.customer_phone);
    mpayload.append("customer_name", payload?.customer_name);
    mpayload.append("subject_id", payload?.subject_id);
    mpayload.append("subject_title", payload?.subject_title);
    mpayload.append("body", payload?.body);
    mpayload.append("assigned_orguser_id", payload?.assigned_orguser_id);
    mpayload.append("priority_status_id", payload?.priority_status_id);
    mpayload.append("has_attachment", parseInt(payload?.has_attachment));
    for (let i = 0; i < payload?.attachments.length; i++) {
      mpayload?.append("attachments", payload?.attachments[i] ? payload?.attachments[i] : null);
    }
    postFormData(
      "/enrollment/bo/create-new-ticket",
      mpayload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const assignVmailToUsers = (payload, onSuccess, onError) => {
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    postData(
      `/enrollment/bo/change-vmail-assignee-bulk`,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  return {
    getVmailMasters,
    postGeneral,
    changeStatus,
    postGeneralFormdata,
    getOrgUsers,
    getProfile,
    createTicket,
    assignVmailToUsers,
  };
};

export default useSupport;
