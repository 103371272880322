import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import SkeletonLoader from "../../../components/SkeletonLoader";
import useDistConfig from "../../../services/useDistConfig";
import MySVG from "../../../assets/app-assets/images/svg/undraw_no_data_re_kwbl.svg";
import WishToaster from "../../../components/WishToaster";

export default function PVLimitAdjustment() {
  const { getPV, updatePV } = useDistConfig();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    distributor_id: "",
    pv: "",
  });
  const [fetchPayload, setFetchPayload] = useState({ distributor_id: "" });
  const [report, setReport] = useState({});

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Distributor Configuration", linkTo: "/distconfig" });
  breadcrumbs.push({ title: "PV Limit Adjustment", linkTo: "/distupdatepv" });

  const getLists = () => {
    setLoading(true);
    getPV(
      fetchPayload,
      (data) => {
        setReport(data);
        setLoading(false);
      },
      (error) => {
        setReport({});
        setLoading(false);
      }
    );
  };
  const submitPV = () => {
    if (payload.distributor_id && payload.pv) {
      setLoading(true);
      updatePV(
        payload,
        (data) => {
          console.log(data);
          getLists();
          WishToaster({ toastTitle: "Success", toastType: "success", toastMessage: "Success!" });
        },
        (error) => {
          setLoading(false);
          WishToaster({ toastTitle: "Uh oh!", toastMessage: error });
        }
      );
    } else {
      WishToaster({ toastTitle: "Uh oh!", toastMessage: "Please enter all fields" });
    }
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Distributor ID :</label>
              <WishSingleLineText
                placeholder={"Enter distributor ID"}
                initialValue={fetchPayload.distributor_id}
                onChange={(value) => {
                  setFetchPayload((prevState) => ({ ...prevState, distributor_id: value }));
                }}
              />
            </div>
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                onClick={(e) => {
                  getLists();
                }}
              >
                Filter
              </button>
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  setFetchPayload({
                    distributor_id: "",
                  });
                  setPayload({ distributor_id: "", pv: "" });
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <SkeletonLoader rows={12} />
            </div>
          </>
        ) : report[0]?.daily_limit ? (
          <>
            <h5>Current Data</h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Direct seller ID : {report[0]?.distributor_id}
                    </td>
                    <td className="text-center" width={"50%"}>
                      Daily limit : {report[0]?.daily_limit}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 className="mt-2">Update Values</h5>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label htmlFor="txtPV" className="col-sm-3 col-form-label text-align-end">
                    New PV
                  </label>
                  <div className="col-sm-9  p-0">
                    <input
                      id="txtPV"
                      name="txtPV"
                      placeholder="Enter new PV"
                      className="form-control"
                      required="required"
                      type="number"
                      value={payload.pv}
                      onChange={(e) => {
                        setPayload({ ...payload, pv: e.target.value, distributor_id: report[0]?.distributor_id });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="d-flex justify-content-flex-end">
                  <button className="btn btn-primary" onClick={submitPV} disabled={loading}>
                    {loading ? <div className="spinner-border text-light" role="status"></div> : null}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img alt="empty_image" src={MySVG} style={{ opacity: "0.5", width: "10vw" }} />
              <h5 className="mt-3">No results</h5>
            </div>
          </div>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="3" pageTitle="PV Limit Adjustment" header="PV Limit Adjustment" breadcrumbs={breadcrumbs}>
      {renderFilters()}
      {renderData()}
    </PageLayout>
  );
}
