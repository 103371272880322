/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";

const navItems = [
  { id: "1", to: "/", iconClass: "la la-home", menuTitle: "Home" },
  { id: "3", to: "/distributor", iconClass: "las la-address-card", menuTitle: "Distributor" },
  { id: "2", to: "/sales", iconClass: "la la-briefcase", menuTitle: "Sales" },
  { id: "5", to: "/commissionshome", iconClass: "las la-dollar-sign", menuTitle: "Commission" },
  { id: "6", to: "/reports", iconClass: "las la-file-excel", menuTitle: "Reports" },
  { id: "7", to: "/announcements", iconClass: "las la-bullhorn", menuTitle: "Announcements" },
  { id: "8", to: "/support", iconClass: "las la-envelope", menuTitle: "Support" },
  { id: "9", to: "/confighome", iconClass: "las la-key", menuTitle: "Configurations" },
];

const NavItem = ({ to, activeIndex, currentIndex, iconClass, menuTitle }) => {
  return (
    <li className={activeIndex === currentIndex ? "active nav-item" : "nav-item"}>
      <Link to={to}>
        <i className={iconClass}></i>
        <span className="menu-title" data-i18n="">
          {menuTitle}
        </span>
      </Link>
    </li>
  );
};

export default class PageSideMenu extends Component {
  render() {
    return (
      <div
        className={"main-menu menu-fixed menu-dark menu-accordion menu-shadow" + this.props.className ?? " "}
        data-scroll-to-active="true"
        data-img="./assets/app-assets/images/backgrounds/04.jpg"
      >
        <div className="navbar-header">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mr-auto">
              <Link className="navbar-brand" to="/">
                <img className="brand-logo" alt="INDUSVIVA" src="../assets/app-assets/images/ico/apple-touch-icon.png" />
                <h3 className="brand-text">INDUSVIVA</h3>
              </Link>
            </li>
            <li className="nav-item d-md-none">
              <a className="nav-link close-navbar" href="#">
                <i className="las la-times-circle"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="navigation-background"></div>
        <div className="main-menu-content">
          <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            {navItems.map((item) => (
              <NavItem key={item.to} to={item.to} activeIndex={this.props.activeIndex} currentIndex={item.id} iconClass={item.iconClass} menuTitle={item.menuTitle} />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
