/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import WishFlexBox from "./WishFlexBox";
import useAuthentication from "../services/useAuthentication";
import { NOTIFICATIONS_LIMIT } from "../services/Constants";
import { useEffect } from "react";
import useLocalStorage from "react-use-localstorage";
import useMasters from "../services/useMasters";
import { useState } from "react";
import moment from "moment";
import WishToaster from "../components/WishToaster";

export default function PageTopMenu({ className = "", pageTitle = "" }) {
  const { loggedInUser } = useMasters();
  const navigateTo = useNavigate();
  const { error, logout } = useAuthentication();
  const [distributor, setDistributor] = useLocalStorage("orgUser", "");

  useEffect(() => {
    if (error) {
      WishToaster({ toastMessage: error });
    }
  }, [error]);

  const logoutUser = () => {
    if (distributor === "") {
      navigateTo("/signin");
    } else {
      const distributorDetails = JSON.parse(distributor);

      if (distributorDetails.user_name === "") {
        navigateTo("/signin");
      } else {
        const credentials = {
          user_name: distributorDetails.user_name,
          // isReadyToAuthenticate: true,
        };
        logout(credentials, (logoutResponse) => {
          if (logoutResponse) {
            setDistributor("");
            navigateTo("/signin");
          }
        });
      }
    }
  };

  return (
    <nav className={"header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light " + (className ?? " ")}>
      <div className="navbar-wrapper">
        <div className="navbar-container content">
          <div className="collapse navbar-collapse show" id="navbar-mobile">
            <ul className="nav navbar-nav mr-auto float-left">
              <li className="nav-item mobile-menu d-md-none mr-auto">
                <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                  <i className="las la-bars font-large-1"></i>
                </a>
              </li>
              <li className="nav-item d-none d-md-block">
                <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                  <i className="las la-bars"></i>
                </a>
              </li>
            </ul>
            <ul className="nav navbar-nav float-right">
              <li className="dropdown dropdown-language nav-item">
                <a className="nav-link">{pageTitle}</a>
              </li>
              <li className="dropdown dropdown-user nav-item">
                <a className="dropdown-toggle nav-link dropdown-user-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="avatar avatar-online">
                    <img src="../assets/app-assets/images/portrait/PlaceHolder.png" alt="avatar" />
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right" id="topMenuDropDown">
                  <div className="arrow_box_right">
                    <div className="dropdown-item">
                      <span className="avatar avatar-online">
                        <img src="./assets/app-assets/images/portrait/PlaceHolder.png" alt="avatar" />
                        <span className="user-name text-bold-700 ml-1">{loggedInUser?.user_name}</span>
                      </span>
                    </div>
                    <div className="pl-2">
                      <small>{loggedInUser?.distributor_rank?.rank_title}</small>
                    </div>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/settings">
                      <i className="las la-cog"></i> Settings
                    </Link>
                    <a className="dropdown-item clickable" onClick={logoutUser}>
                      <i className="las la-sign-out-alt"></i> Signout / Switch Account
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
