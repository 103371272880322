import React, { useEffect, useState } from "react";

import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import Pagination from "../../../components/Pagination";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import SkeletonLoader from "../../../components/SkeletonLoader";

import data from "../../../data/Data.json";
import useReport from "../../../services/useReport";

export default function VolumeReport() {
  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Reports", linkTo: "/reportsandtrends" });
  breadcrumbs.push({ title: "Volume Report", linkTo: "/volumereport" });

  const options = { day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true };

  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const { fetchReport } = useReport();
  const [error, setError] = useState();
  const [payload, setPayload] = useState({
    page_no: 1,
    total_no_of_pages: 0,
    distributor_id: 1001,
    week: "",
    position: 1,
    order_status: 0,
  });

  const fields = [
    {
      id: "position",
      label: "Position",
      type: "select",
      input_type: "",
      disabled: false,
      default_value: 1,
      place_holder: "",
      options: [
        { id: 1, name: "Left" },
        { id: 2, name: "Right" },
      ],
      select_key: "id",
      select_label: "name",
      multiple: false,
      visible: true,
      is_or_filter: false,
      side1: [],
      side2: [],
    },
    {
      id: "order_status",
      label: "Order Type",
      type: "select",
      input_type: "",
      disabled: false,
      default_value: 0,
      place_holder: "",
      options: [
        { id: 1, name: "First order" },
        { id: 2, name: "Re-order" },
      ],
      select_key: "id",
      select_label: "name",
      multiple: false,
      visible: true,
      is_or_filter: false,
      side1: [],
      side2: [],
    },
  ];

  useEffect(() => {
    getLists();
  }, []);

  const getLists = () => {
    setLoading(true);
    fetchReport(
      27,
      payload,
      (data, response) => {
        setReport(data);
        if (data) payload.total_no_of_pages = data?.total_no_of_pages;
        setError(response.message);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setPayload({ ...payload, [id]: parseInt(value) });
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Distributor ID :</label>
              <WishSingleLineText
                placeholder={"Enter distributor id"}
                initialValue={payload.distributor_id}
                onChange={(value) => {
                  setPayload((prevState) => ({ ...prevState, distributor_id: parseInt(value) }));
                }}
              />
            </div>
            <div className="col-sm-3">
              <label>Week :</label>
              <WishSingleLineText
                placeholder={"Enter week id"}
                initialValue={payload.week}
                onChange={(value) => {
                  setPayload((prevState) => ({ ...prevState, week: parseInt(value) }));
                }}
              />
            </div>
            {fields.map((field) => (
              <div className="col-sm-3">
                <label htmlFor={field.id}>
                  {field.label}
                  {field.required && <span className="required">*</span>}
                </label>
                <select className="form-control" id={field.id} onChange={handleChange} value={payload[field.id]} disabled={field.disabled} required={field.required}>
                  <option value="" selected>
                    Choose...
                  </option>
                  {field?.options?.map((option) => (
                    <option key={option[field?.select_key]} value={option[field?.select_key]}>
                      {option[[field?.select_label]]}
                    </option>
                  ))}
                </select>
              </div>
            ))}
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                onClick={(e) => {
                  payload.page_no = 1;
                  getLists();
                }}
              >
                Filter
              </button>
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  payload.page_no = 1;
                  payload.total_no_of_pages = 0;
                  payload.week_id = "";
                  payload.activity_status_id = "";
                  payload.position = "";
                  getLists();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <>
            <SkeletonLoader rows={12} />
          </>
        ) : (
          <>
            <h4>Direct Seller details</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {data?.volumeReport?.volume_report_dist?.map((item) => (
                      <th scope="col">{item.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {report?.distdata?.length > 0 ? (
                    report?.distdata?.map((item) => (
                      <tr>
                        {data?.volumeReport?.volume_report_dist?.map((head, i) => (
                          <td>{head.type === "date" ? new Date(item[head.key]).toLocaleString("en-US", options) : item[head.key]}</td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={13}>
                        <strong>{error}</strong>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <h4 className="mt-2">Order data</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {data?.volumeReport?.volume_report_orders?.map((item) => (
                      <th scope="col">{item.title}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {report?.Ordersdata?.length > 0 ? (
                    report?.Ordersdata?.map((item) => (
                      <tr>
                        {data?.volumeReport?.volume_report_orders?.map((head, i) => (
                          <td>{head.type === "date" ? new Date(item[head.key]).toLocaleString("en-US", options) : item[head.key]}</td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-center">
                      <td colSpan={13}>
                        <strong>{error}</strong>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              pageno={report?.page_no}
              onPageChange={(page) => {
                payload.page_no = page;
                getLists();
              }}
              totalPages={report?.total_no_of_pages}
            />
          </>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="6" pageTitle="Analysis" header={`Volume Report`} breadcrumbs={breadcrumbs}>
      {renderFilters()}
      {renderData()}
    </PageLayout>
  );
}
