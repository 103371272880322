//import logo from './logo.svg';
import React, { useEffect, useState, Suspense } from "react";
import useLocalStorage from "react-use-localstorage";
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";
import WishToaster from "./components/WishToaster";
import "./App.css";
import FallbackUI from "./views/NoNetworkFallback";
import SignIn from "./views/authentication/signin";
import CommissionsScreen from "./views/commission/CommissionScreen";
import UploadAdjustments from "./views/commission/UploadAdjustments";
import UploadDeductions from "./views/commission/UploadDeductions";
import Error404 from "./views/Error404";
import Error403 from "./views/Error403";
import RolesnPermissions from "./views/configurations/RolesnPermissions";
import Users from "./views/configurations/Users";
import MaintenanceMode from "./views/MaintenanceMode";
import DistConfigHome from "./views/distributor/distconfig/DistConfigHome";
import UpdateDistStatus from "./views/distributor/distconfig/DistStatusUpdate";
import PVLimitAdjustment from "./views/distributor/distconfig/PVLimitAdjustment";
import CFLCFRAdjustment from "./views/distributor/distconfig/CFLCFRAdjustment";
import Settings from "./views/Settings";
import VolumeReport from "./views/reports/distributor/VolumeReport";
import RankAdjustment from "./views/distributor/distconfig/RankAdjustment";
import PasswordReset from "./views/distributor/distconfig/PasswordReset";
import VmailAllmails from "./views/support/vmail/VmailAllmails";
import IdTransfer from "./views/distributor/genealogy/IDTransfer/IDTransfer";

const Dashboard = React.lazy(() => import("./views/Dashboard"));
const Announcements = React.lazy(() => import("./views/announcements/Announcements"));
const NewAnnouncements = React.lazy(() => import("./views/announcements/NewAnnouncement"));
const Distributor = React.lazy(() => import("./views/distributor/distributor"));
const Enrollment = React.lazy(() => import("./views/distributor/enrollment/Enrollment"));
const GenerateLink = React.lazy(() => import("./views/distributor/enrollment/GenerateLink"));
const OngoingEnrollments = React.lazy(() => import("./views/distributor/enrollment/OngoingEnrollments"));
const EnrollUser = React.lazy(() => import("./views/distributor/enrollment/EnrollUser"));
const ProfileDashboard = React.lazy(() => import("./views/distributor/profile/ProfileDashboard"));
const Verifications = React.lazy(() => import("./views/distributor/verification/Verification"));
const VerificationList = React.lazy(() => import("./views/distributor/verification/VerificationList"));
const Support = React.lazy(() => import("./views/support/Support"));
const Vmail = React.lazy(() => import("./views/support/vmail/Vmail"));
const MyTickets = React.lazy(() => import("./views/support/mytickets/MyTickets"));
const TicketDetails = React.lazy(() => import("./views/support/mytickets/TicketDetails"));
const CreateTicket = React.lazy(() => import("./views/support/mytickets/CreateTicket"));
const Reports = React.lazy(() => import("./views/reports/ReportsHome"));
const ReportGeneral = React.lazy(() => import("./views/reports/ReportGeneral"));
const DistReportsHome = React.lazy(() => import("./views/reports/distributor/DistReportsHome"));
const CommissionReportsHome = React.lazy(() => import("./views/reports/commission/CommissionReportsHome"));
const SalesReportsHome = React.lazy(() => import("./views/reports/sales/SalesReportsHome"));
const PCMReportsHome = React.lazy(() => import("./views/reports/pcm/PCMReportsHome"));
const VOTMReportsHome = React.lazy(() => import("./views/reports/votm/VOTMReportsHome"));
const MiscellaneousReportsHome = React.lazy(() => import("./views/reports/miscellaneous/MiscReportsHome"));
const GenealogyHome = React.lazy(() => import("./views/distributor/genealogy/GenealogyHome"));
const ViewGenealogy = React.lazy(() => import("./views/distributor/genealogy/Genealogy/ViewGenealogy"));
const GenealogySettings = React.lazy(() => import("./views/distributor/genealogy/Genealogy/GenealogySettings"));
const CommissionReports = React.lazy(() => import("./views/reports/commission/CommissionReports"));
const CongratulationsPage = React.lazy(() => import("./views/CongratulationsPage"));
const Error500 = React.lazy(() => import("./views/Error500"));
const RewardsHome = React.lazy(() => import("./views/distributor/rewards/RewardsHome"));
const ConfigHome = React.lazy(() => import("./views/configurations/ConfigHome"));
const CommissionsHome = React.lazy(() => import("./views/commission/CommissionsHome"));
const Vouchers = React.lazy(() => import("./views/sales/vouchers/VoucherList"));
const NewEditVoucher = React.lazy(() => import("./views/sales/vouchers/NewEditVoucher"));
const Sales = React.lazy(() => import("./views/sales/Sales"));
const PlaceOrder = React.lazy(() => import("./views/sales/PlaceOrder"));
const MyOrders = React.lazy(() => import("./views/sales/MyOrders"));
const OrderDetails = React.lazy(() => import("./views/sales/OrderDetails"));

const App = () => {
  const [isSignedIn, setIsSignedIn] = useLocalStorage("isSignedIn", false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [authRoutes, setAuthRoutes] = useLocalStorage("authRoutes", []);
  const [maintenanceMode, setMaintenanceMode] = useState(false); // Set this to true when maintenance is required. on.

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  const Protected = ({ isSignedIn, children }) => {
    if (!isSignedIn) {
      WishToaster({
        toastMessage: "You need to login.",
        toastType: "error",
      });
      return <Navigate to="/signin" replace />;
    }
    console.log(JSON.parse(authRoutes), "authroutes");
    // Check if the current path is allowed for the user's role
    const currentPath = window.location.pathname;
    const allowedRoutes = JSON.parse(authRoutes)?.map((menu) => menu.route_value);

    if (!allowedRoutes.includes(currentPath)) {
      console.log("Unauthorized Access", currentPath, allowedRoutes);
      return <Error403 />;
    }

    return children;
  };

  const loadingUI = (
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );

  const routesData = [
    { path: "/", component: <Dashboard /> },
    { path: "/settings", component: <Settings /> },
    { path: "/announcements", component: <Announcements /> },
    { path: "/newannouncement", component: <NewAnnouncements /> },
    { path: "/sales", component: <Sales /> },
    { path: "/placeorder", component: <PlaceOrder /> },
    { path: "/myorders", component: <MyOrders /> },
    { path: "/orderdetails", component: <OrderDetails /> },
    { path: "/distributor", component: <Distributor /> },
    { path: "/rewards", component: <RewardsHome /> },
    { path: "/enrollmentformsandlinks", component: <Enrollment /> },
    { path: "/generatelink", component: <GenerateLink /> },
    { path: "/ongoingenrollments", component: <OngoingEnrollments /> },
    { path: "/enrolluser", component: <EnrollUser /> },
    { path: "/distributorprofile", component: <ProfileDashboard /> },
    { path: "/verifications", component: <Verifications /> },
    { path: "/verificationslist", component: <VerificationList /> },
    { path: "/support", component: <Support /> },
    { path: "/vmail", component: <Vmail /> },
    { path: "/vmailpreassigned", component: <VmailAllmails /> },
    { path: "/mytickets", component: <MyTickets /> },
    { path: "/ticketdetails", component: <TicketDetails /> },
    { path: "/createticket", component: <CreateTicket /> },
    { path: "/reports", component: <Reports /> },
    { path: "/distributorreports", component: <DistReportsHome /> },
    { path: "/commissionreports", component: <CommissionReportsHome /> },
    { path: "/distributorcommissionreport", component: <CommissionReports /> },
    { path: "/salesreports", component: <SalesReportsHome /> },
    { path: "/pcmreports", component: <PCMReportsHome /> },
    { path: "/votmreports", component: <VOTMReportsHome /> },
    { path: "/miscellaneousreports", component: <MiscellaneousReportsHome /> },
    { path: "/viewgenealogy", component: <ViewGenealogy /> },
    { path: "/geneologysettings", component: <GenealogySettings /> },
    { path: "/confighome", component: <ConfigHome /> },
    { path: "/rolesnpermissions", component: <RolesnPermissions /> },
    { path: "/usermanagement", component: <Users /> },
    { path: "/commissionshome", component: <CommissionsHome /> },
    { path: "/distconfig", component: <DistConfigHome /> },
    { path: "/commissionscreen", component: <CommissionsScreen /> },
    { path: "/uploadadjustments", component: <UploadAdjustments /> },
    { path: "/uploaddeductions", component: <UploadDeductions /> },
    { path: "/vouchers", component: <Vouchers /> },
    { path: "/newvoucher", component: <NewEditVoucher /> },
    { path: "/genealogyhome", component: <GenealogyHome /> },
    { path: "/distupdatestatus", component: <UpdateDistStatus /> },
    { path: "/distupdatepv", component: <PVLimitAdjustment /> },
    { path: "/distupdatecflcfr", component: <CFLCFRAdjustment /> },
    { path: "/distrankadjustment", component: <RankAdjustment /> },
    { path: "/distpasswordreset", component: <PasswordReset /> },
    { path: "/volumereport", component: <VolumeReport /> },
    { path: "/idtransfer", component: <IdTransfer /> },
    // Add more routes as needed...
  ];

  const reportRoutes = [
    { path: "/weeklyperformerreport", reportID: 1, title: "Weekly Performers" },
    { path: "/rankadvancementreport", reportID: 2, title: "Rank Advancement Report" },
    { path: "/updownlinesearch", reportID: 3, title: "Upline/Downline Search" },
    { path: "/bankreport", reportID: 4, title: "Bank Report" },
    { path: "/sponsoreddistributorreport", reportID: 5, title: "Sponsored Distributor Report" },
    { path: "/progressreport", reportID: 6, title: "Progress Report" },
    { path: "/salesregister", reportID: 7, title: "Sales Register" },
    { path: "/csbsummary", reportID: 8, title: "CSB Summary" },
    { path: "/salessummary", reportID: 9, title: "Sales Summary" },
    { path: "/compliedcommission", reportID: 10, title: "Compiled Commission" },
    { path: "/detailedcompliedcommission", reportID: 11, title: "Detailed Compiled Commission" },
    { path: "/compliedcoapplicantbonus", reportID: 12, title: "Compiled Co-applicant Bonus Report" },
    { path: "/rmbachieversreport", reportID: 13, title: "RMB Achievers Report" },
    { path: "/adjustmentlist", reportID: 14, title: "Adjustment List" },
    { path: "/deductionlist", reportID: 15, title: "Deduction List" },
    { path: "/bipachieversreport", reportID: 16, title: "BIP Achievers Report" },
    { path: "/trbachieversreport", reportID: 17, title: "TRB Achievers Report" },
    { path: "/digirockstarachieversreport", reportID: 18, title: "DigiRockstar Achievers Report" },
    { path: "/gstreport", reportID: 19, title: "GST Report" },
    { path: "/votmuplinesdownlinesreport", reportID: 20, title: "VOTM Uplines/Downlines Report" },
    { path: "/pcmdownlinesreport", reportID: 21, title: "PCM Uplines/Downlines Report" },
    { path: "/bonusreport", reportID: 22, title: "Bonus Report" },
    { path: "/votmmembershipreport", reportID: 23, title: "VOTM Membership Report" },
    { path: "/pcmmembershipreport", reportID: 24, title: "PCM Membership Report" },
    { path: "/topearnersreport", reportID: 25, title: "Top Earners Report" },
    { path: "/distributormasterreport", reportID: 26, title: "Distributor Master Report" },
    { path: "/newdistributorreport", reportID: 28, title: "New Distributor Report" },
  ];

  const reportRoutesElements = reportRoutes.map((route) => (
    <Route
      key={route.path}
      path={route.path}
      element={
        <Protected isSignedIn={isSignedIn}>
          <Suspense fallback={loadingUI}>
            <ReportGeneral reportID={route.reportID} title={route.title} />
          </Suspense>
        </Protected>
      }
    />
  ));

  return maintenanceMode ? (
    <MaintenanceMode />
  ) : (
    <div>
      {isOnline ? (
        <Router>
          <Routes>
            <Route path="/*" element={<Error404 />} />
            <Route path="/403" element={<Error403 />} />
            <Route path="/paymentsuccess" element={<CongratulationsPage />} />
            <Route path="/paymentfailed" element={<Error500 />} />

            <Route path="/signin" element={<SignIn setisAutheticated={setIsSignedIn} setAuthRoutes={setAuthRoutes} />} />
            {reportRoutesElements}
            {routesData.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={
                  <Protected isSignedIn={isSignedIn}>
                    <Suspense fallback={loadingUI}>{item.component}</Suspense>
                  </Protected>
                }
              />
            ))}
            {/* <Route
              path="/"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Dashboard />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/settings"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Settings />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/announcements"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Announcements />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/newannouncement"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <NewAnnouncements />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/sales"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Sales />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/placeorder"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <PlaceOrder />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/myorders"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <MyOrders />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/orderdetails"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <OrderDetails />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distributor"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Distributor />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/rewards"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <RewardsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/enrollmentformsandlinks"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Enrollment />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/generatelink"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <GenerateLink />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/ongoingenrollments"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <OngoingEnrollments />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/enrolluser"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <EnrollUser />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distributorprofile"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <ProfileDashboard />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/verifications"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Verifications />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/verificationslist"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <VerificationList />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/support"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Support />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/vmail"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Vmail />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/vmailpreassigned"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <VmailAllmails />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/mytickets"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <MyTickets />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/ticketdetails"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <TicketDetails />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/createticket"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CreateTicket />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/reports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Reports />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distributorreports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <DistReportsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/commissionreports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CommissionReportsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distributorcommissionreport"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CommissionReports />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/salesreports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <SalesReportsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/pcmreports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <PCMReportsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/votmreports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <VOTMReportsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/miscellaneousreports"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <MiscellaneousReportsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/viewgenealogy"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <ViewGenealogy />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/geneologysettings"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <GenealogySettings />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/paymentsuccess"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CongratulationsPage />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/paymentfailed"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Error500 />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/confighome"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <ConfigHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/rolesnpermissions"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <RolesnPermissions />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/usermanagement"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Users />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/commissionshome"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CommissionsHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distconfig"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <DistConfigHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/commissionscreen"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CommissionsScreen />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/uploadadjustments"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <UploadAdjustments />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/uploaddeductions"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <UploadDeductions />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/vouchers"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <Vouchers />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/newvoucher"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <NewEditVoucher />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/genealogyhome"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <GenealogyHome />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distupdatestatus"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <UpdateDistStatus />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distupdatepv"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <PVLimitAdjustment />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distupdatecflcfr"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <CFLCFRAdjustment />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distrankadjustment"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <RankAdjustment />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/distpasswordreset"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <PasswordReset />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/volumereport"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <VolumeReport />
                  </Suspense>
                </Protected>
              }
            />
            <Route
              path="/idtransfer"
              element={
                <Protected isSignedIn={isSignedIn}>
                  <Suspense fallback={loadingUI}>
                    <IdTransfer />
                  </Suspense>
                </Protected>
              }
            />*/}
          </Routes>
        </Router>
      ) : (
        // Render the fallback UI component
        <FallbackUI />
      )}
    </div>
  );
};

export default App;
