import moment from "moment";
import WishSelect from "../../../../components/WishFormComponents/WishSelect";
import WishSingleLineText from "../../../../components/WishFormComponents/WishSingleLineText";
import WishDateControl from "../../../../components/WishFormComponents/WishDateControl";

export default function BasicDetailsIDTransfer({ enrollmentMasterData, IDTransferPayload, setIDTransferPayload }) {
  return (
    <div className="col-sm-12 col-md-12 col-lg-6 mt-1">
      <h5>Personal details</h5>
      <hr />

      <WishSelect
        data={enrollmentMasterData?.titles}
        label="Title"
        initialValue={IDTransferPayload?.distpersonal?.mtitle_id}
        placeholder={IDTransferPayload?.distpersonal?.mtitle_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, mtitle_id: parseInt(newValue) },
          });
        }}
      />
      <WishSingleLineText
        label="First Name"
        initialValue={IDTransferPayload?.distpersonal?.first_name}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, first_name: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Last Name"
        initialValue={IDTransferPayload?.distpersonal?.last_name}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, last_name: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Display Name"
        initialValue={IDTransferPayload?.distributor_data?.display_name}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distributor_data: { ...IDTransferPayload.distributor_data, display_name: newValue },
          });
        }}
      />
      <WishDateControl
        label="Date of birth"
        initialValue={moment(IDTransferPayload?.distpersonal?.dob).format("YYYY-MM-DD")}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, dob: new Date(newValue).toISOString() },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.languages}
        label="Language"
        initialValue={IDTransferPayload?.distpersonal?.mlanguage_id}
        placeholder={IDTransferPayload?.distpersonal?.mlanguage_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, mlanguage_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.gender}
        label="Gender"
        initialValue={IDTransferPayload?.distpersonal?.mgender_id}
        placeholder={IDTransferPayload?.distpersonal?.mgender_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, mgender_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.marital_status}
        label="Marital Status"
        initialValue={IDTransferPayload?.distpersonal?.mmarital_status_id}
        placeholder={IDTransferPayload?.distpersonal?.mmarital_status_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, mmarital_status_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.profession}
        label="Profession"
        initialValue={IDTransferPayload?.distpersonal?.mprofession_id}
        placeholder={IDTransferPayload?.distpersonal?.mprofession_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, mprofession_id: parseInt(newValue) },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.monthly_income}
        label="Monthly Income"
        initialValue={IDTransferPayload?.distpersonal?.mmonthly_income_id}
        placeholder={IDTransferPayload?.distpersonal?.mmonthly_income_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, mmonthly_income_id: parseInt(newValue) },
          });
        }}
      />
      <WishSingleLineText
        label="Aadhar Number (optional)"
        initialValue={IDTransferPayload?.distpersonal?.aadhar_no}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, aadhar_no: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="PAN Number (optional)"
        initialValue={IDTransferPayload?.distpersonal?.pan_no}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, pan_no: newValue.toUpperCase().replace(/\s/g, "") },
          });
        }}
      />
      <WishSingleLineText
        label="GST Number (optional)"
        initialValue={IDTransferPayload?.distpersonal?.gst_no}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload.distpersonal, gst_no: newValue.toUpperCase().replace(/\s/g, "") },
          });
        }}
      />
    </div>
  );
}
