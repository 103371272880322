import { useEffect, useState } from "react";
import WishSingleLineText from "../../../../components/WishFormComponents/WishSingleLineText";
import ListGroup from "../../../../components/ListGroup";
import WishModal from "../../../../components/WishModal";
import CommonAPIs from "../../../../services/commonAPIs";

export default function ContactDetailsIDTransfer({ IDTransferPayload, setIDTransferPayload }) {
  const { fetchLocationsFromPincode } = CommonAPIs();
  const [validPincode, setValidPincode] = useState(0);
  const [pincode, setPincode] = useState(0);
  const [modalId, setModalId] = useState(true);
  const [locations, setLocations] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const validatePincode = function (pincode) {
    if (pincode !== "") {
      const regex = new RegExp("^\\d{6}$");
      console.log(pincode + ", " + regex.test(pincode));
      setValidPincode(regex.test(pincode) === true ? 2 : 1);
      setPincode(pincode);
    } else {
      setValidPincode(0);
      setShowSearchResults(false);
    }
    return validPincode;
  };

  const selectPincode = (item) => {
    console.log(item);
    if (IDTransferPayload?.distpersonal?.is_addresses_same) {
      setIDTransferPayload((prevState) => ({
        ...prevState,
        distpersonal: {
          ...prevState.distpersonal,
          permanent_postalcode_id: item.postalcode_id,
          permanent_postalcode: item.postalcode,
          permanent_post_name: item.post_name,
          permanent_city_id: item.city_id,
          permanent_city_name: item.city_name,
          permanent_district_id: item.district_id,
          permanent_district_name: item.district_name,
          permanent_state_id: item.state_id,
          permanent_state_name: item.state_name,
          permanent_country_id: item.country_id,
          permanent_country_name: item.country_name,
          communication_postalcode_id: item.postalcode_id,
          communication_postalcode: item.postalcode,
          communication_post_name: item.post_name,
          communication_city_id: item.city_id,
          communication_city_name: item.city_name,
          communication_district_id: item.district_id,
          communication_district_name: item.district_name,
          communication_state_id: item.state_id,
          communication_state_name: item.state_name,
          communication_country_id: item.country_id,
          communication_country_name: item.country_name,
        },
      }));
    } else if (!IDTransferPayload?.distpersonal?.is_addresses_same && modalId) {
      setIDTransferPayload((prevState) => ({
        ...prevState,
        distpersonal: {
          ...prevState.distpersonal,
          permanent_postalcode_id: item.postalcode_id,
          permanent_postalcode: item.postalcode,
          permanent_post_name: item.post_name,
          permanent_city_id: item.city_id,
          permanent_city_name: item.city_name,
          permanent_district_id: item.district_id,
          permanent_district_name: item.district_name,
          permanent_state_id: item.state_id,
          permanent_state_name: item.state_name,
          permanent_country_id: item.country_id,
          permanent_country_name: item.country_name,
        },
      }));
    } else {
      setIDTransferPayload((prevState) => ({
        ...prevState,
        distpersonal: {
          ...prevState.distpersonal,
          communication_postalcode_id: item.postalcode_id,
          communication_postalcode: item.postalcode,
          communication_post_name: item.post_name,
          communication_city_id: item.city_id,
          communication_city_name: item.city_name,
          communication_district_id: item.district_id,
          communication_district_name: item.district_name,
          communication_state_id: item.state_id,
          communication_state_name: item.state_name,
          communication_country_id: item.country_id,
          communication_country_name: item.country_name,
        },
      }));
    }
  };

  const selectLocationModal = function () {
    return (
      <WishModal id="dlgSelectLocation" title="Select Location" finishTitle="Select">
        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <div className="col-9">
                <input id="txtPincode" name="txtPincode" type="text" className="form-control" placeholder="Pincode" defaultValue="" onChange={(e) => validatePincode(e.target.value)} />
                <small className={validPincode === 0 || validPincode === 2 ? "hidden" : "text-danger"}>Invalid pincode</small>
              </div>
              <div className="col-3">
                <button
                  disabled={!(validPincode === 2)}
                  className="btn btn-block btn-primary"
                  onClick={(e) =>
                    fetchLocationsFromPincode(pincode, (data) => {
                      setLocations(data);
                      setShowSearchResults(true);
                      console.log(locations);
                    })
                  }
                >
                  Search
                </button>
              </div>
              <div className={"col-12 pt-2 " + (showSearchResults === true ? "" : "hidden")}>
                <ListGroup items={locations} onSelect={(item) => selectPincode(item)} label="post_name"></ListGroup>
              </div>
            </div>
          </div>
        </div>
      </WishModal>
    );
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-6 mt-1">
      <h5>Contact details</h5>
      <hr />

      <WishSingleLineText
        label="Mobile Number"
        initialValue={IDTransferPayload?.distpersonal?.phone}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload?.distpersonal, phone: newValue.replace(/[^\d]/g, "") },
          });
        }}
      />
      <WishSingleLineText
        label="Email Address"
        initialValue={IDTransferPayload?.distpersonal?.email}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: { ...IDTransferPayload?.distpersonal, email: newValue },
          });
        }}
      />
      {/* <h6>Permanent Address </h6> */}
      <WishSingleLineText
        label="Address Line 1"
        initialValue={IDTransferPayload?.distpersonal?.permanent_address_line1}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: {
              ...IDTransferPayload?.distpersonal,
              permanent_address_line1: newValue,
              communication_address_line1: IDTransferPayload?.distpersonal?.is_addresses_same ? newValue : "",
            },
          });
        }}
      />
      <WishSingleLineText
        label="Address Line 2"
        initialValue={IDTransferPayload?.distpersonal?.permanent_address_line2}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            distpersonal: {
              ...IDTransferPayload?.distpersonal,
              permanent_address_line2: newValue,
              communication_address_line2: IDTransferPayload?.distpersonal?.is_addresses_same ? newValue : "",
            },
          });
        }}
      />
      <div className="form-group">
        <div className="row">
          <label htmlFor="txtDeliveryLocation" className="col-4 col-form-label">
            Post, City, District
          </label>
          <div
            className="input-group col-8"
            data-toggle="modal"
            data-target="#dlgSelectLocation"
            onClick={() => {
              setModalId(true);
            }}
          >
            <input
              id="txtDeliveryLocation"
              name="txtDeliveryLocation"
              placeholder="Post, City, District"
              type="text"
              className="form-control"
              disabled={true}
              value={
                IDTransferPayload?.distpersonal?.permanent_post_name
                  ? IDTransferPayload?.distpersonal?.permanent_post_name +
                    " " +
                    IDTransferPayload?.distpersonal?.permanent_city_name +
                    " " +
                    IDTransferPayload?.distpersonal?.permanent_district_name
                  : ""
              }
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <a>
                  <i className="las la-map-marker"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <label htmlFor="txtDeliveryLocation" className="col-4 col-form-label">
            State, Country, Postal Code
          </label>
          <div
            className="input-group col-8"
            data-toggle="modal"
            data-target="#dlgSelectLocation"
            onClick={() => {
              setModalId(true);
            }}
          >
            <input
              id="txtDeliveryLocation"
              name="txtDeliveryLocation"
              placeholder="State, Country, Postal Code"
              type="text"
              className="form-control"
              disabled={true}
              value={
                IDTransferPayload?.distpersonal?.permanent_state_name
                  ? IDTransferPayload?.distpersonal?.permanent_state_name +
                    " " +
                    IDTransferPayload?.distpersonal?.permanent_country_name +
                    " " +
                    IDTransferPayload?.distpersonal?.permanent_postalcode
                  : ""
              }
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <a>
                  <i className="las la-map-marker"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectLocationModal()}
    </div>
  );
}
