import React, { useEffect, useState } from "react";
import data from "../../data/Data.json";
import WishSimpleCard from "../../components/WishSimpleCard";
import useConfig from "../../services/useConfig";
import PageLayout from "../../components/PageLayout";
import SkeletonLoader from "../../components/SkeletonLoader";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import WishToaster from "../../components/WishToaster";
import WishModal from "../../components/WishModal";
import { Link } from "react-router-dom";
import { AppUtils } from "../../services/AppUtils";

export default function RolesnPermissions() {
  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Configurations", linkTo: "/confighome" });
  breadcrumbs.push({ title: "Roles & Permissions", linkTo: "/rolesnpermissions" });
  const options = { day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true };

  const { createRole, getUsersRoutesRoles, assignRoutesToRole } = useConfig();
  const [masters, setMasters] = useState({});
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    orguser_id: JSON.parse(localStorage.getItem("orgUser"))?.orguser_id,
    orguser_name: JSON.parse(localStorage.getItem("orgUser"))?.user_name,
    role_name: "",
  });
  const [assignPayload, setAssignPayload] = useState({ role_id: "", route_ids: [] });

  useEffect(() => {
    getLists();
  }, []);

  useEffect(() => {
    console.log(assignPayload, "assignPayload");
  }, [assignPayload]);

  const getLists = () => {
    setLoading(true);
    getUsersRoutesRoles(
      {},
      (data) => {
        setMasters(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const assignRoutes = () => {
    setLoading(true);
    assignRoutesToRole(
      assignPayload,
      (data) => {
        getLists(data);
        AppUtils.hideDialog("dlgAssignRoutes");
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const renderData = (row, head) => {
    switch (head?.type) {
      case "date":
        return row[head?.key] ? new Date(row[head?.key]).toLocaleString("en-IN", options) : "No data found";
      case "status":
        return row[head?.key] ? "Closed" : "Open";
      default:
        return row[head?.key];
    }
  };

  const Table = ({ data, heads }) => {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {heads?.map((head) => (
                <th key={head.key}>{head.title}</th>
              ))}
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr key={row.role_id}>
                {heads.map((head) => (
                  <td key={`${row.role_id}-${head.key}`}>{renderData(row, head)}</td>
                ))}
                <td>
                  <Link to={""} data-toggle="modal" data-target="#dlgAssignRoutes">
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) => {
                        setAssignPayload({ ...assignPayload, role_id: row.role_id, route_ids: row.routes.map((item) => item.id) });
                      }}
                    >
                      Edit routes
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-plus"></i> Add new role
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Role :</label>
              <WishSingleLineText
                placeholder={"Enter role name"}
                onChange={(newValue) => {
                  setPayload({ ...payload, role_name: newValue });
                }}
              />
            </div>
            <div className="col-sm-12">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setLoading(true);
                  createRole(
                    payload,
                    (data) => {
                      setMasters({ ...masters, roles: data });
                      setLoading(false);
                      WishToaster({ toastType: "success", toastMessage: "Successfully created role!" });
                    },
                    (error) => {
                      setLoading(false);
                    }
                  );
                }}
              >
                Create
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="9" pageTitle="Roles & Permissions" header={`Roles & Permissions`} breadcrumbs={breadcrumbs}>
      {renderFilters()}
      <WishSimpleCard>{loading ? <SkeletonLoader rows={12} /> : <Table data={masters?.roles} heads={data?.roles} />}</WishSimpleCard>
      <WishModal id="dlgAssignRoutes" title="Assign permissions to role" hideCancelButton={true} noFooter={true} finishTitle="Proceed" modalSize="modal-lg">
        <div className="form-group row">
          <label htmlFor="ddTitle" className="col-sm-3 col-form-label text-align-end">
            Assigned routes
          </label>
          <div className="col-sm-9" style={{ maxHeight: "250px", overflowY: "scroll", border: "1px solid #babfc7" }}>
            {masters?.routes?.map((item) => (
              <div className="custom-controls-stacked pt-1">
                <div className="custom-control custom-checkbox">
                  <input
                    name="checkbox"
                    id={item.id}
                    type="checkbox"
                    className="custom-control-input"
                    value={item.id}
                    checked={assignPayload.route_ids.includes(item.id)}
                    onChange={(e) => {
                      let newValue = parseInt(e.target.value);
                      if (e.target.checked) {
                        setAssignPayload((prevState) => ({
                          ...prevState,
                          route_ids: [...prevState.route_ids, newValue],
                        }));
                      } else {
                        setAssignPayload((prevState) => ({
                          ...prevState,
                          route_ids: prevState.route_ids.filter((prevId) => prevId !== newValue),
                        }));
                      }
                    }}
                  />
                  <label htmlFor={item.id} className="custom-control-label">
                    {item.name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light ml-auto float-right"
          disabled={loading}
          onClick={(e) => {
            assignRoutes();
          }}
        >
          {loading ? (
            <>
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <span>Assigning...</span>
            </>
          ) : (
            <>
              <span>Assign routes</span>
            </>
          )}
        </button>
      </WishModal>
    </PageLayout>
  );
}
