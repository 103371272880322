import React, { useEffect, useState } from "react";
import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import SkeletonLoader from "../../../components/SkeletonLoader";
import useDistConfig from "../../../services/useDistConfig";
import MySVG from "../../../assets/app-assets/images/svg/undraw_no_data_re_kwbl.svg";
import WishToaster from "../../../components/WishToaster";

export default function RankAdjustment() {
  const { getCurrentRank, updateRank } = useDistConfig();
  const [loading, setLoading] = useState(false);
  const [fetchPayload, setFetchPayload] = useState({ distributor_id: "" });
  const [report, setReport] = useState({});
  const [payload, setPayload] = useState({
    id: "",
    distributor_id: "",
    achived_rank_hierarchy_id: "",
    achived_rank_title: "",
    paid_rank_hierarchy_id: "",
    paid_rank_tile: "",
    week_id: "",
    remarks: "",
  });

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Distributor Configuration", linkTo: "/distconfig" });
  breadcrumbs.push({ title: "Rank Adjustment", linkTo: "/distrankadjustment" });

  const getLists = () => {
    setLoading(true);
    getCurrentRank(
      fetchPayload,
      (data) => {
        setReport(data);
        setLoading(false);
      },
      (error) => {
        setReport({});
        setLoading(false);
      }
    );
  };

  const submitRankAdj = () => {
    if (payload.distributor_id && payload.achived_rank_hierarchy_id && payload.paid_rank_hierarchy_id && payload.remarks) {
      payload.week_id = report?.current_rank_data[0]?.week_id;
      payload.id = report?.current_rank_data[0]?.id;

      setLoading(true);
      updateRank(
        payload,
        (data) => {
          console.log(data);
          setPayload({ id: "", distributor_id: "", achived_rank_hierarchy_id: "", achived_rank_title: "", paid_rank_hierarchy_id: "", paid_rank_tile: "", week_id: "", remarks: "" });
          getLists();
          WishToaster({ toastTitle: "Success", toastType: "success", toastMessage: "Success!" });
        },
        (error) => {
          setLoading(false);
          WishToaster({ toastTitle: "Uh oh!", toastMessage: error });
        }
      );
    } else {
      WishToaster({ toastTitle: "Uh oh!", toastMessage: "Please enter all fields" });
    }
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Distributor ID :</label>
              <WishSingleLineText
                placeholder={"Enter distributor ID"}
                initialValue={fetchPayload.distributor_id}
                onChange={(value) => {
                  setFetchPayload((prevState) => ({ ...prevState, distributor_id: value }));
                }}
              />
            </div>
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                onClick={(e) => {
                  getLists();
                }}
              >
                Filter
              </button>
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  setFetchPayload({
                    distributor_id: "",
                  });
                  setPayload({ distributor_id: "", pv: "" });
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <SkeletonLoader rows={12} />
            </div>
          </>
        ) : report?.current_rank_data ? (
          <>
            <h5>Current Data {report?.current_rank_data && `- Week ${report?.current_rank_data[0]?.week_id}`}</h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Direct seller ID :<strong> {report?.current_rank_data[0]?.distributor_id}</strong>
                    </td>
                    <td className="text-center" width={"50%"}>
                      Name :<strong> {report?.current_rank_data[0]?.distributor_name}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Achieved Rank :<strong> {report?.current_rank_data[0]?.achieved_rank_title}</strong>
                    </td>
                    <td className="text-center" width={"50%"}>
                      Paid Rank :<strong> {report?.current_rank_data[0]?.paid_rank_title}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 className="mt-2">Update Ranks</h5>
            <div className="col-md-6 col-lg-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label text-align-end">Achieved Rank</label>
                <div className="col-sm-9  p-0">
                  <select
                    className="form-control"
                    value={payload.achived_rank_hierarchy_id}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        achived_rank_hierarchy_id: e.target.value,
                        achived_rank_title: report?.ranks.find((item) => item.hierarchy_id == e.target.value)?.title,
                        distributor_id: report?.current_rank_data[0]?.distributor_id,
                      });
                    }}
                  >
                    <option value={""}>Choose...</option>
                    {report?.ranks?.map((item) => (
                      <option value={item.hierarchy_id}>{item.title}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-3 col-form-label text-align-end">Paid Rank</label>
                <div className="col-sm-9  p-0">
                  <select
                    className="form-control"
                    value={payload.paid_rank_hierarchy_id}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        paid_rank_hierarchy_id: e.target.value,
                        paid_rank_tile: report?.ranks.find((item) => item.hierarchy_id == e.target.value)?.title,
                        distributor_id: report?.current_rank_data[0]?.distributor_id,
                      });
                    }}
                  >
                    <option value={""}>Choose...</option>
                    {report?.ranks?.map((item) => (
                      <option value={item.hierarchy_id}>{item.title}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="txtRemarks" className="col-sm-3 col-form-label text-align-end">
                  Remarks
                </label>
                <div className="col-sm-9  p-0">
                  <textarea
                    id="txtRemarks"
                    name="txtRemarks"
                    placeholder="Enter remarks"
                    className="form-control"
                    required="required"
                    value={payload.remarks}
                    maxLength={500}
                    rows={5}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        remarks: e.target.value,
                        distributor_id: report?.current_rank_data[0]?.distributor_id,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="d-flex justify-content-flex-end">
                  <button className="btn btn-primary" onClick={submitRankAdj} disabled={loading}>
                    {loading ? <div className="spinner-border text-light" role="status"></div> : null}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img alt="empty_image" src={MySVG} style={{ opacity: "0.5", width: "10vw" }} />
              <h5 className="mt-3">No results</h5>
            </div>
          </div>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="3" pageTitle="Rank Adjustment" header="Rank Adjustment" breadcrumbs={breadcrumbs}>
      {renderFilters()}
      {renderData()}
    </PageLayout>
  );
}
