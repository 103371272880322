/* eslint-disable no-undef */
export const AppUtils = {
  showDialog: function (dialogId) {
    $("#" + dialogId).modal("show");
  },

  hideDialog: function (dialogId) {
    $("#" + dialogId).modal("hide");
  },

  getFormData: function (formId) {
    var formEl = document.getElementById(formId);

    if (formEl) {
      return new FormData(formEl);
    }

    return null;
  },

  getFormDataWithFormObject: function (form) {
    //var formEl = document.getElementById(formId);

    if (form) {
      return new FormData(form);
    }

    return null;
  },

  createFormData: function (object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  },

  createURLSearchParams: function (object) {
    const encodedParams = new URLSearchParams();
    Object.keys(object).forEach((key) => encodedParams.set(key, object[key]));
    return encodedParams;
  },

  getValueFromArray: function (valueArray, matchField, matchValue, value) {
    return valueArray.filter((x) => x[matchField] === matchValue)[0][value];
  },
  appendParamsToURL: (obj, url) => {
    const params = new URLSearchParams();
    // Loop through the object properties
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && key !== "page_no" && key !== "total_no_of_pages") {
        if (Array.isArray(obj[key])) {
          params.append(key, JSON.stringify(obj[key]));
        } else {
          params.append(key, obj[key]?.toString());
        }
      }
    }
    const paramString = params.toString();
    const fullURL = `${url}?${paramString}`;
    return fullURL;
  },
  YearList: () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 25 }, (_, index) => {
      const year = currentYear - index;
      return { year_id: year, year_label: year };
    });
    return years;
  },
  months: () => {
    let months = [
      { month_id: 1, month_label: "January" },
      { month_id: 2, month_label: "February" },
      { month_id: 3, month_label: "March" },
      { month_id: 4, month_label: "April" },
      { month_id: 5, month_label: "May" },
      { month_id: 6, month_label: "June" },
      { month_id: 7, month_label: "July" },
      { month_id: 8, month_label: "August" },
      { month_id: 9, month_label: "September" },
      { month_id: 10, month_label: "October" },
      { month_id: 11, month_label: "November" },
      { month_id: 12, month_label: "December" },
    ];
    return months;
  },
  formatDateToIndianStandard: (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-IN", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  },
  formatDate: (datetime) => {
    const dateObj = new Date(datetime);
    const today = new Date();

    if (dateObj.toDateString() === today.toDateString()) {
      // Case 1: Today's date
      const time = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return time;
    } else if (dateObj.getFullYear() === today.getFullYear()) {
      // Case 2: Within the current year
      const date = dateObj.toLocaleString("en-US", { month: "short", day: "numeric" });
      return date;
    } else {
      // Case 3: Previous year
      const formattedDate = dateObj.toLocaleString("en-US", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      return formattedDate;
    }
  },
};
