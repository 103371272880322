import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "./AppUtils";

const useReport = () => {
  const { postData } = useAPIs();
  const loggedInUser = JSON.parse(localStorage.getItem("orgUser"));

  const reportURLList = [
    { key: 1, url: "/reports/weekly-performance-report" },
    { key: 2, url: "/reports/rank-advancement-history-report" },
    { key: 3, url: "/reports/upline-downline-search" },
    { key: 4, url: "/reports/bank-report" },
    { key: 5, url: "/reports/sponsored-distributor-report" },
    { key: 6, url: "/reports/progress-report" },
    { key: 7, url: "/reports/sales-register-report" },
    { key: 8, url: "/reports/csb-summary" },
    { key: 9, url: "/reports/sales-summary" },
    { key: 10, url: "/reports/weekly-compiled-commission-report" },
    { key: 11, url: "/reports/list-of-distributors-weekly-commission-report" },
    { key: 12, url: "/reports/weekly-co-applicant-bonus-report" },
    { key: 13, url: "/reports/weekly-rmb-achievers-report" },
    { key: 14, url: "/reports/adjustments-list" },
    { key: 15, url: "/reports/deductions-list" },
    { key: 16, url: "/reports/business-incubator-bonus-report" },
    { key: 17, url: "/reports/monthwise-trb-report" },
    { key: 18, url: "/reports/monthwise-digi-rockstar-report" },
    { key: 19, url: "/reports/gst-report" },
    { key: 20, url: "/reports/upline-downline-votm-search" },
    { key: 21, url: "/reports/upline-downline-pcm-search" },
    { key: 22, url: "/reports/bonus-report" },
    { key: 23, url: "/reports/votm-membership-report" },
    { key: 24, url: "/reports/pcm-membership-report" },
    { key: 25, url: "/reports/top-earners" },
    { key: 26, url: "/reports/master-search-report" },
    { key: 27, url: "/reports/volume-report" },
    { key: 28, url: "/reports/bo/new-direct-seller-report" },
  ];
  const filterURLList = [
    { key: 1, url: "/reports/weekly-performance-report-filters" },
    { key: 2, url: "/reports/rank-advancement-report-filters" },
    { key: 3, url: "/reports/upline-downline-search-filters" },
    { key: 4, url: "/reports/bank-report-filters" },
    { key: 5, url: "/reports/sponsored-distributor-report-filters" },
    { key: 6, url: "/reports/progress-report-filters" },
    { key: 7, url: "/reports/sales-register-report-filters" },
    { key: 8, url: "/reports/csb-summary-filters" },
    { key: 9, url: "/reports/sales-summary-filters" },
    { key: 10, url: "/reports/weekly-compiled-commission-report-filters" },
    { key: 11, url: "/reports/list-of-distributors-weekly-commission-report-filters" },
    { key: 12, url: "/reports/weekly-co-applicant-bonus-report-filters" },
    { key: 13, url: "/reports/weekly-rmb-achievers-report-filters" },
    { key: 14, url: "/reports/adjustments-list-filters" },
    { key: 15, url: "/reports/deductions-list-filters" },
    { key: 16, url: "/reports/business-incubator-bonus-report-filters" },
    { key: 17, url: "/reports/monthwise-trb-report-filters" },
    { key: 18, url: "/reports/monthwise-digi-rockstar-report-filters" },
    { key: 19, url: "/reports/gst-report-filters" },
    { key: 20, url: "/reports/upline-downline-votm-search-filters" },
    { key: 21, url: "/reports/upline-downline-pcm-search-filters" },
    { key: 22, url: "/reports/bonus-report-filters" },
    { key: 23, url: "/reports/votm-membership-filters" },
    { key: 24, url: "/reports/pcm-membership-filters" },
    { key: 25, url: "/reports/top-earners-filters" },
    { key: 26, url: "/reports/master-search-report-filters" },
    { key: 28, url: "/reports/bo/new-direct-seller-filters" },
  ];
  const downloadReportURLs = [
    { key: 1, url: "/reports/weekly-performance-report-download" },
    { key: 2, url: "/reports/rank-advancement-report-download" },
    { key: 4, url: "/reports/bank-report-download" },
    { key: 5, url: "/reports/sponsored-distributor-download" },
    { key: 6, url: "/reports/progress-report-download" },
    { key: 7, url: "/reports/download-sales-register-report" },
    { key: 8, url: "/reports/download-csb-summary" },
    { key: 9, url: "/reports/download-sales-summary" },
    { key: 10, url: "/reports/download-weekly-compiled-commission-report" },
    { key: 11, url: "/reports/download-list-of-distributors-weekly-commission-report" },
    { key: 12, url: "/reports/download-weekly-co-applicant-bonus-report" },
    { key: 13, url: "/reports/download-weekly-rmb-achievers-report" },
    { key: 14, url: "/reports/download-adjustments-list" },
    { key: 15, url: "/reports/download-deductions-list" },
    { key: 16, url: "/reports/download-business-incubator-bonus-report" },
    { key: 17, url: "/reports/download-monthwise-trb-report" },
    { key: 18, url: "/reports/download-monthwise-digi-rockstar-report" },
    { key: 19, url: "/reports/download-gst-report" },
    { key: 22, url: "/reports/bonus-report-download" },
    { key: 25, url: "/reports/top-earners-download" },
    { key: 28, url: "/reports/bo/new-direct-seller-report-download" },
  ];

  const fetchFilter = (URLid, onSuccess, onError) => {
    let URLtoCall = filterURLList.find((item) => item.key === URLid);
    postData(
      URLtoCall?.url,
      {},
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const fetchReport = (URLid, payload, onSuccess, onError) => {
    let URLtoCall = reportURLList.find((item) => item.key === URLid);
    payload.orguser_id = loggedInUser.orguser_id;
    payload.orguser_name = loggedInUser.user_name;
    postData(
      URLtoCall?.url,
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const downloadReport = (URLid, payload, onSuccess, onError) => {
    let URLtoCall = downloadReportURLs.find((item) => item.key === URLid);
    console.log(AppUtils.appendParamsToURL(payload, URLtoCall?.url), process.env.REACT_APP_BASE_URL);
    window.open(`${process.env.REACT_APP_BASE_URL}${AppUtils.appendParamsToURL(payload, URLtoCall?.url)}`, "_blank");
  };

  const getWeeklyCommission = (payload, onSuccess, onError) => {
    postData(
      "/reports/weekly-commission-report-of-a-distributor",
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  return {
    fetchFilter,
    fetchReport,
    getWeeklyCommission,
    downloadReport,
  };
};

export default useReport;
