import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import SkeletonLoader from "../../../components/SkeletonLoader";
import useDistConfig from "../../../services/useDistConfig";
import MySVG from "../../../assets/app-assets/images/svg/undraw_no_data_re_kwbl.svg";
import WishToaster from "../../../components/WishToaster";

export default function CFLCFRAdjustment() {
  const { getCFData, updateCFData } = useDistConfig();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({});
  const [payload, setPayload] = useState({
    distributor_id: report[0]?.distributor_id,
    cfr: "",
    cfl: "",
    remarks: "",
    id: report[0]?.id,
    week: report[0]?.week_id,
  });
  const [fetchPayload, setFetchPayload] = useState({ distributor_id: "" });

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Distributor Configuration", linkTo: "/distconfig" });
  breadcrumbs.push({ title: "CFL/CFR Adjustment", linkTo: "/distupdatecflcfr" });

  const getLists = () => {
    setLoading(true);
    getCFData(
      fetchPayload,
      (data) => {
        setReport(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        setReport({});
      }
    );
  };

  useEffect(() => {
    console.log(payload);
  }, [payload]);

  const updateCFLCFR = () => {
    if (payload.cfl && payload.cfr && payload.distributor_id && payload.remarks) {
      setLoading(true);
      updateCFData(
        payload,
        (data) => {
          console.log(data);
          getLists();
          WishToaster({ toastTitle: "Success", toastType: "success", toastMessage: "Success!" });
        },
        (error) => {
          setLoading(false);
          WishToaster({ toastTitle: "Uh oh!", toastMessage: error });
        }
      );
    } else {
      WishToaster({ toastTitle: "Uh oh!", toastMessage: "Please enter all fields" });
    }
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Distributor ID :</label>
              <WishSingleLineText
                placeholder={"Enter distributor ID"}
                initialValue={fetchPayload.distributor_id}
                onChange={(value) => {
                  setFetchPayload((prevState) => ({ ...prevState, distributor_id: value }));
                }}
              />
            </div>
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                onClick={(e) => {
                  getLists();
                }}
              >
                Filter
              </button>
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  setFetchPayload({
                    distributor_id: "",
                  });
                  setPayload({ distributor_id: report[0]?.distributor_id, cfr: "", cfl: "", remarks: "", id: report[0]?.id, week: report[0]?.week_id });
                  setReport({});
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <SkeletonLoader rows={6} />
            </div>
          </>
        ) : report[0]?.week_id ? (
          <>
            <h5>Current Data</h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center" colSpan={"12"}>
                      {" "}
                      <h6>Week : {report[0]?.week_id}</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Direct seller ID : {report[0]?.distributor_id}
                    </td>
                    <td className="text-center" width={"50%"}>
                      Direct seller name : {report[0]?.distributor_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Total LGV : {report[0]?.total_lgv}
                    </td>
                    <td className="text-center" width={"50%"}>
                      Total RGV : {report[0]?.total_rgv}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      CFL : {report[0]?.cfl}
                    </td>
                    <td className="text-center" width={"50%"}>
                      CFR : {report[0]?.cfr}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 className="mt-2">Update Values</h5>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label htmlFor="txtCFL" className="col-sm-3 col-form-label text-align-end">
                    CFL
                  </label>
                  <div className="col-sm-9  p-0">
                    <input
                      id="txtCFL"
                      name="txtCFL"
                      placeholder="Enter new CFL"
                      className="form-control"
                      required="required"
                      type="number"
                      value={payload.cfl}
                      onChange={(e) => {
                        setPayload({ ...payload, cfl: e.target.value, id: report[0]?.id, week: report[0]?.week_id, distributor_id: report[0]?.distributor_id });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label htmlFor="txtCFL" className="col-sm-3 col-form-label text-align-end">
                    CFR
                  </label>
                  <div className="col-sm-9  p-0">
                    <input
                      id="txtCFR"
                      name="txtCFR"
                      placeholder="Enter new CFR"
                      type="number"
                      className="form-control"
                      required="required"
                      value={payload.cfr}
                      onChange={(e) => {
                        setPayload({ ...payload, cfr: e.target.value, id: report[0]?.id, week: report[0]?.week_id, distributor_id: report[0]?.distributor_id });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label htmlFor="txtRemarks" className="col-sm-3 col-form-label text-align-end">
                    Remarks
                  </label>
                  <div className="col-sm-9  p-0">
                    <textarea
                      id="txtRemarks"
                      name="txtRemarks"
                      placeholder="Enter remarks"
                      className="form-control"
                      required="required"
                      value={payload.remarks}
                      maxLength={500}
                      rows={5}
                      onChange={(e) => {
                        setPayload({ ...payload, remarks: e.target.value, id: report[0]?.id, week: report[0]?.week_id, distributor_id: report[0]?.distributor_id });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="d-flex justify-content-flex-end">
                  <button className="btn btn-primary" onClick={updateCFLCFR} disabled={loading}>
                    {loading ? <div className="spinner-border text-light" role="status"></div> : null}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img alt="empty_image" src={MySVG} style={{ opacity: "0.5", width: "10vw" }} />
              <h5 className="mt-3">No results</h5>
            </div>
          </div>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="3" pageTitle="CFL/CFR Adjustment" header="CFL/CFR Adjustment" breadcrumbs={breadcrumbs}>
      {renderFilters()}
      {renderData()}
    </PageLayout>
  );
}
