import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PageLayout from "../../components/PageLayout";
import useCommission from "../../services/useCommission";
import { AppUtils } from "../../services/AppUtils";
import WishSimpleCard from "../../components/WishSimpleCard";
import SkeletonLoader from "../../components/SkeletonLoader";

const SwalAlert = withReactContent(Swal);

export default function CommissionsScreen() {
  const location = useLocation();
  const { fetchButtonStatuses, initiateStep } = useCommission();
  const [loading, setLoading] = useState(false);
  const [commissionStatus, setCommissionStatus] = useState([]);

  // Track the state of each button (whether it is clicked and completed)
  const [buttonStates, setButtonStates] = useState([]);

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Commissions", linkTo: "/commissionshome" });
  breadcrumbs.push({ title: `Commission week ${location.state.week}`, linkTo: "/commissionscreen" });

  useEffect(() => {
    setLoading(true);
    getButtonStatuses();
    // Set up an interval to fetch button statuses every 15 seconds
    const interval = setInterval(() => {
      getButtonStatuses();
    }, 10000);

    // Clear the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getButtonStatuses = () => {
    fetchButtonStatuses(
      { week_id: location.state.week },
      (data) => {
        setCommissionStatus(data);
        // Initialize the buttonStates based on the received data
        const initialButtonStates = data.map((item) => ({
          isClicked: item.is_clicked || false,
          isCompleted: item.is_completed || false,
        }));
        setButtonStates(initialButtonStates);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const ProcessStatus = (object) => {
    const { start_time, end_time, user_name } = object;

    if (!start_time && !end_time) {
      return <div>Not Started</div>;
    } else if (start_time && !end_time) {
      const formattedStartTime = AppUtils.formatDateToIndianStandard(start_time);
      return (
        <div>
          Process started by {user_name || "Unknown User"} on {formattedStartTime}
        </div>
      );
    } else if (start_time && end_time) {
      const formattedStartTime = AppUtils.formatDateToIndianStandard(start_time);
      const formattedEndTime = AppUtils.formatDateToIndianStandard(end_time);
      return (
        <div>
          Process finished on {formattedEndTime}. Initiated by {user_name || "Unknown User"} on {formattedStartTime}
        </div>
      );
    } else {
      return null; // In case of any other conditions, return null or handle as required.
    }
  };

  // Function to handle the button click
  const handleButtonClick = (index, item) => {
    // Disable the button and set it to clicked state
    const updatedButtonStates = [...buttonStates];
    updatedButtonStates[index] = {
      isClicked: true,
      isCompleted: false,
    };
    setButtonStates(updatedButtonStates);

    initiateStep(
      item.url,
      { week_id: location.state.week, button_id: item.button_id },
      (data) => {
        updatedButtonStates[index] = {
          isClicked: true,
          isCompleted: true,
        };
        setButtonStates(updatedButtonStates);
      },
      (error) => {
        SwalAlert.fire({
          icon: "error",
          title: "Oops!",
          text: error,
        });
      }
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <SkeletonLoader rows={12} />
        ) : (
          <>
            <div className="col-sm-12">
              <div className="card bg-blue-grey bg-lighten-4">
                <div className="card-content collapse show">
                  <div className="card-header place-order-pv-count">
                    <span className="badge badge-lg badge-primary">{location.state?.commission_status?.message}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Step</th>
                    <th scope="col">Status</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {commissionStatus?.map((item, index) => (
                    <tr key={item.button_id}>
                      <td>{item.name}</td>
                      <td>{ProcessStatus(item)}</td>
                      <td style={{ display: "flex", justifyContent: "center", borderTop: "1px solid #e3ebf3" }}>
                        <button
                          className={`btn btn-sm btn-primary float-right mt-1 ${buttonStates[index].isClicked && !buttonStates[index].isCompleted ? "disabled" : ""} ${
                            buttonStates[index].isClicked && buttonStates[index].isCompleted ? "btn-success" : ""
                          }`}
                          disabled={!buttonStates[index].isCompleted && index > 0 && !buttonStates[index - 1].isCompleted}
                          onClick={(e) => {
                            handleButtonClick(index, item);
                          }}
                        >
                          {buttonStates[index].isClicked && !buttonStates[index].isCompleted ? (
                            <div className="spinner-border text-light" role="status"></div>
                          ) : buttonStates[index].isClicked && buttonStates[index].isCompleted ? (
                            "Completed"
                          ) : (
                            "Start"
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="5" pageTitle="Commissions" header="Commissions" breadcrumbs={breadcrumbs}>
      {renderData()}
    </PageLayout>
  );
}
