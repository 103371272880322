import React, { useEffect, useState } from "react";
import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import useSupport from "../../../services/useSupport";
import Pagination from "../../../components/Pagination";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { AppUtils } from "../../../services/AppUtils";
import Select from "react-select";
import WishToaster from "../../../components/WishToaster";

export default function VmailAllmails() {
  const { postGeneral, getVmailMasters, assignVmailToUsers, getOrgUsers } = useSupport();
  const [masters, setMasters] = useState([]);
  const [mails, setMails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({ page_no: 1, total_no_of_pages: 0, subject_id: null });
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [assignMailPayload, setAssignMailPayload] = useState({ parent_vmail_ids: [], reassignee_orguser_id: "", subject_id: null });
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    getVmailMasters((data) => {
      setMasters(data);
    });
    getOrgUsers({ search_user_name: "" }, (data) => {
      setUsers(data);
    });
  }, []);

  useEffect(() => {
    getMails();
  }, [payload.subject_id]);

  useEffect(() => {
    console.table(assignMailPayload);
  }, [assignMailPayload]);

  const getMails = () => {
    setLoading(true);
    postGeneral(
      10,
      payload,
      (data) => {
        setMails(data);
        setLoading(false);
        payload.total_no_of_pages = data?.total_no_of_pages;
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const options = users.map((user) => ({
    value: user.orguser_id,
    label: user.orguser_name,
  }));

  const handleUserSelect = (selectedOption) => {
    // postGeneral(8, { parent_ticket_id: routerState.state?.parent_ticket_id, reassignee_orguser_id: selectedOption.value }, (data) => {
    //   getLists();
    // });
    setSelectedUser(selectedOption);
    setAssignMailPayload({ ...assignMailPayload, reassignee_orguser_id: selectedOption.value });
  };

  const handleAssign = () => {
    if (!assignMailPayload.subject_id && assignMailPayload.parent_vmail_ids.length <= 0) {
      WishToaster({ toastType: "error", toastMessage: "Select v-mails" });
      return;
    }
    if (!assignMailPayload.reassignee_orguser_id) {
      WishToaster({ toastType: "error", toastMessage: "Select a user" });
      return;
    }
    setLoading(true);
    assignVmailToUsers(
      assignMailPayload,
      (data) => {
        WishToaster({ toastType: "success", toastMessage: "Success!" });
        getMails();
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const toggleCheckbox = (parent_vmail_id) => {
    setAssignMailPayload((prevAssignMailPayload) => {
      const parent_vmail_ids = [...prevAssignMailPayload.parent_vmail_ids];

      if (parent_vmail_ids.includes(parent_vmail_id)) {
        // Deselect the checkbox (remove the parent_vmail_id)
        parent_vmail_ids.splice(parent_vmail_ids.indexOf(parent_vmail_id), 1);
      } else {
        // Select the checkbox (add the parent_vmail_id)
        parent_vmail_ids.push(parent_vmail_id);
      }

      return {
        ...prevAssignMailPayload,
        parent_vmail_ids,
      };
    });
  };

  const handleSelectAllChange = () => {
    // Toggle the "Select All" checkbox
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      // Select all emails
      const allEmailIds = mails?.vmails?.map((item) => item.parent_vmail_id);
      setAssignMailPayload((prevAssignMailPayload) => ({
        ...prevAssignMailPayload,
        parent_vmail_ids: allEmailIds,
      }));
    } else {
      // Deselect all emails
      setAssignMailPayload((prevAssignMailPayload) => ({
        ...prevAssignMailPayload,
        parent_vmail_ids: [],
      }));
    }
  };

  const bulkSelect = () => {
    return (
      <div className="text-center mb-1">
        {payload.subject_id && !assignMailPayload.subject_id ? (
          <>
            All {assignMailPayload.parent_vmail_ids.length} conversations on this page are selected.{" "}
            <span
              href=""
              className="clickable text-primary ml-1"
              onClick={(e) => {
                setAssignMailPayload({ ...assignMailPayload, subject_id: payload.subject_id });
              }}
            >
              Select all {mails?.unread_mail_count} conversations in {masters?.subjects?.find((element) => element.subject_id == payload.subject_id)?.subject_title}
            </span>
          </>
        ) : (
          <>
            All {mails.unread_mail_count} conversations in {masters?.subjects?.find((element) => element.subject_id == payload.subject_id)?.subject_title} are selected{" "}
            {payload.subject_id && assignMailPayload.subject_id ? (
              <span
                href=""
                className="clickable text-primary ml-1"
                onClick={(e) => {
                  setAssignMailPayload({ ...assignMailPayload, subject_id: null, parent_vmail_ids: [] });
                  setSelectAllChecked(false);
                }}
              >
                Remove Selection
              </span>
            ) : null}
          </>
        )}
      </div>
    );
  };

  return (
    <PageLayout activeSideMenu="8" pageTitle="V-mail - Unassigned" header="V-mail - Unassigned">
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label htmlFor={"subject_id"}>Subject ID</label>
              <select
                className="form-control"
                id={"subject_id"}
                placeholder="Subject Id"
                value={payload.subject_id}
                onChange={(e) => {
                  setAssignMailPayload({ ...assignMailPayload, parent_vmail_ids: [] });
                  setPayload({ ...payload, subject_id: e.target.value, page_no: 1 });
                  setSelectAllChecked(false);
                }}
              >
                <option value="" selected>
                  Choose...
                </option>
                {masters?.subjects?.map((item) => (
                  <option value={item.subject_id} key={item.subject_id}>
                    {item.subject_title}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  e.preventDefault();
                  setPayload({ page_no: 1, total_no_of_pages: 0, subject_id: "" });
                  setAssignMailPayload({ ...assignMailPayload, parent_vmail_ids: [] });
                  setSelectAllChecked(false);
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
      <WishSimpleCard>
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet" />
        <div>
          <div className="wraper bootstrap snippets bootdeys bootdey">
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-body">
                    {loading ? (
                      <SkeletonLoader rows={12} />
                    ) : mails?.vmails?.length > 0 ? (
                      <>
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-2">
                          <div>
                            <strong>Assign mails to:</strong>
                          </div>
                          <div>
                            <Select options={options} isSearchable value={selectedUser} onChange={handleUserSelect} />
                            <button className="btn btn-sm btn-primary align-self-start mt-1" onClick={handleAssign}>
                              Assign
                            </button>
                          </div>
                        </div>
                        {selectAllChecked && bulkSelect()}
                        <table className={`table table-hover mails`}>
                          <colgroup>
                            <col width="10%" />
                            <col width="20%" />
                            <col width="50%" />
                            <col width="20%" />
                          </colgroup>
                          <thead className="thead-light">
                            <th>
                              <label className="cr-styled">
                                <input type="checkbox" onChange={handleSelectAllChange} checked={selectAllChecked} />
                                <i className="fa"></i>
                              </label>
                            </th>
                            <th>From</th>
                            <th>Subject</th>
                            <th></th>
                          </thead>
                          <tbody>
                            {mails?.vmails?.map((item) => (
                              <tr key={item.parent_vmail_id}>
                                <td className="col-1">
                                  <label className="cr-styled">
                                    <input
                                      type="checkbox"
                                      onChange={() => toggleCheckbox(item.parent_vmail_id)}
                                      checked={assignMailPayload.parent_vmail_ids.includes(item.parent_vmail_id)}
                                    />
                                    <i className="fa"></i>
                                  </label>
                                </td>
                                <td className="clickable">
                                  <p className={item.is_read ? null : "bold"}>{item.from_name}</p>
                                </td>
                                <td className="clickable" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "1px" }}>
                                  <span className={item.is_read ? null : "bold"}>{item.title} </span>
                                  <span> - {item.body}</span>
                                </td>
                                <td className="text-right clickable">
                                  {item.has_attachment ? <i className="fa fa-paperclip mr-1"></i> : null}
                                  <span className={item.is_read ? null : "bold"}>{AppUtils.formatDate(item.create_time)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <hr />
                        {mails?.total_no_of_pages && (
                          <>
                            <Pagination
                              pageno={mails?.page_no}
                              onPageChange={(page) => {
                                payload.page_no = page;
                                getMails();
                              }}
                              totalPages={mails?.total_no_of_pages}
                            />
                            <div className="text-right">Total {mails?.unread_mail_count} mails</div>
                          </>
                        )}
                      </>
                    ) : (
                      <h5 className="empty-mail-text">No mails found</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WishSimpleCard>
    </PageLayout>
  );
}
