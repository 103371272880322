import { useEffect, useState } from "react";
import WishSingleLineText from "../../../../components/WishFormComponents/WishSingleLineText";
import useEnrollment from "../../../../services/useEnrollment";
import WishDateControl from "../../../../components/WishFormComponents/WishDateControl";
import WishSelect from "../../../../components/WishFormComponents/WishSelect";
import moment from "moment";

export default function CoApplicantDetailsIDTransfer({ enrollmentMasterData, IDTransferPayload, setIDTransferPayload }) {
  const { getCoAppRelationships } = useEnrollment();
  const [relationships, setRelationships] = useState([]);

  useEffect(() => {
    getCoAppRelationships(IDTransferPayload.basicDetails?.gender_id, IDTransferPayload.basicDetails?.marital_status_id, (data) => {
      setRelationships(data.other_relationships);
    });
  }, []);

  return (
    <div className="col-sm-12 col-md-12 col-lg-6 mt-1">
      <h5>Co-applicant details</h5>
      <hr />
      <WishSingleLineText
        label="Co-Applicant Name"
        initialValue={IDTransferPayload?.coapplicant.coapplicant_name}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_name: newValue },
          });
        }}
      />
      <WishSelect
        label="Co-Applicant Relationship"
        data={relationships}
        initialValue={IDTransferPayload?.coapplicant.coapplicant_relationship_id}
        placeholder={IDTransferPayload?.coapplicant.coapplicant_relationship_id ? false : true}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_relationship_id: parseInt(newValue) },
          });
        }}
      />
      <WishDateControl
        label="Date of birth"
        id="caDOB"
        initialValue={moment(IDTransferPayload?.coapplicant.coapplicant_dob).format("YYYY-MM-DD")}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_dob: newValue },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.gender}
        label="Gender"
        id="caGender"
        placeholder={IDTransferPayload?.coapplicant.coapplicant_gender_id ? false : true}
        initialValue={IDTransferPayload?.coapplicant.coapplicant_gender_id}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_gender_id: parseInt(newValue) },
          });
        }}
      />
      <WishSingleLineText
        label="Mobile Number"
        id="caMobile"
        initialValue={IDTransferPayload?.coapplicant.coapplicant_phone}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_phone: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Email Address"
        id="caEmail"
        initialValue={IDTransferPayload?.coapplicant.coapplicant_email}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_email: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="PAN Number"
        id="caPan"
        initialValue={IDTransferPayload?.coapplicant.coapplicant_pan_no}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, coapplicant_pan_no: newValue.toUpperCase().replace(/\s/g, "") },
          });
        }}
      />
      <WishSingleLineText
        label="Nominee Name"
        id="caNomineeName"
        initialValue={IDTransferPayload?.distpersonal.nominee}
        onChange={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, nominee: newValue },
          });
        }}
      />
      <WishSelect
        label="Nominee Relationship"
        data={enrollmentMasterData?.relationship}
        placeholder={IDTransferPayload?.distpersonal.nominee_mrelationship_id ? false : true}
        initialValue={IDTransferPayload?.distpersonal.nominee_mrelationship_id}
        onSelect={(newValue) => {
          setIDTransferPayload({
            ...IDTransferPayload,
            coapplicant: { ...IDTransferPayload.coapplicant, nominee_mrelationship_id: parseInt(newValue) },
          });
        }}
      />
    </div>
  );
}
