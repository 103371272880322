import React, { useEffect, useState } from "react";
import moment from "moment";
import PageLayout from "../../../components/PageLayout";
import WishSimpleCard from "../../../components/WishSimpleCard";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import SkeletonLoader from "../../../components/SkeletonLoader";
import useDistConfig from "../../../services/useDistConfig";
import MySVG from "../../../assets/app-assets/images/svg/undraw_no_data_re_kwbl.svg";
import WishToaster from "../../../components/WishToaster";

export default function UpdateDistStatus() {
  const { getDistActiveData, updateDistActiveData, getActivityReport } = useDistConfig();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    distributor_id: "",
    status_id: "",
    remarks: "",
  });
  const [fetchPayload, setFetchPayload] = useState({ distributor_id: "" });
  const [report, setReport] = useState({});
  const [activityReport, setActivityReport] = useState([]);

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Distributor Configuration", linkTo: "/distconfig" });
  breadcrumbs.push({ title: "Activity Status", linkTo: "/distupdatestatus" });

  const getLists = () => {
    setLoading(true);
    getDistActiveData(
      fetchPayload,
      (data) => {
        setReport(data);
        setLoading(false);
      },
      (error) => {
        setReport({});
        setLoading(false);
      }
    );
    getActivityReport(
      fetchPayload,
      (data) => {
        setActivityReport(data);
        setLoading(false);
      },
      (error) => {
        setReport({});
        setLoading(false);
      }
    );
  };

  const submitDistStatus = () => {
    if (payload.status_id && payload.distributor_id && payload.remarks) {
      setLoading(true);
      updateDistActiveData(
        payload,
        (data) => {
          console.log(data);
          getLists();
          WishToaster({ toastTitle: "Success", toastType: "success", toastMessage: "Success!" });
        },
        (error) => {
          setLoading(false);
          WishToaster({ toastTitle: "Uh oh!", toastMessage: error });
        }
      );
    } else {
      WishToaster({ toastTitle: "Uh oh!", toastMessage: "Please enter all fields" });
    }
  };

  useEffect(() => {
    console.log(payload);
  }, [payload]);
  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Distributor ID :</label>
              <WishSingleLineText
                placeholder={"Enter distributor ID"}
                initialValue={fetchPayload.distributor_id}
                onChange={(value) => {
                  setFetchPayload((prevState) => ({ ...prevState, distributor_id: value }));
                }}
              />
            </div>
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                onClick={(e) => {
                  getLists();
                }}
              >
                Filter
              </button>
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  setFetchPayload({
                    distributor_id: "",
                  });
                  setPayload({ distributor_id: "", status_id: "", remarks: "" });
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <SkeletonLoader rows={12} />
            </div>
          </>
        ) : report?.distributor_data ? (
          <>
            <h5>Current Data</h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center" colSpan={"12"}>
                      <h6>Direct seller details</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Direct seller ID : {report?.distributor_data[0]?.distributor_id}
                    </td>
                    <td className="text-center" width={"50%"}>
                      Direct seller name : {report?.distributor_data[0]?.distributor_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-center" width={"50%"}>
                      Display name : {report?.distributor_data[0]?.display_name}
                    </td>
                    <td className="text-center" width={"50%"}>
                      Current status : {report?.distributor_data[0]?.current_status}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 className="mt-2">Update Values</h5>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label htmlFor="txtCFL" className="col-sm-3 col-form-label text-align-end">
                    New Status
                  </label>
                  <div className="col-sm-9  p-0">
                    <select
                      id="txtStatus"
                      name="txtStatus"
                      className="form-control"
                      value={payload.status_id}
                      onChange={(e) => {
                        setPayload({ ...payload, status_id: e.target.value, distributor_id: report?.distributor_data[0]?.distributor_id });
                      }}
                    >
                      <option value={""}>Choose...</option>
                      {report?.active_status_list?.map((item) => (
                        <option value={item.id}>{item.title}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group row">
                  <label htmlFor="txtRemarks" className="col-sm-3 col-form-label text-align-end">
                    Remarks
                  </label>
                  <div className="col-sm-9  p-0">
                    <textarea
                      id="txtRemarks"
                      name="txtRemarks"
                      placeholder="Enter remarks"
                      className="form-control"
                      required="required"
                      value={payload.remarks}
                      maxLength={500}
                      rows={5}
                      onChange={(e) => {
                        setPayload({ ...payload, remarks: e.target.value, distributor_id: report?.distributor_data[0]?.distributor_id });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="d-flex justify-content-flex-end">
                  <button className="btn btn-primary" onClick={submitDistStatus} disabled={loading}>
                    {loading ? <div className="spinner-border text-light" role="status"></div> : null}
                    Save
                  </button>
                </div>
              </div>
            </div>
            <h5>History</h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Direct seller ID</th>
                    <th>Old status</th>
                    <th>New status</th>
                    <th>Remarks</th>
                    <th>Changed by</th>
                    <th>Changed on</th>
                  </tr>
                </thead>
                <tbody>
                  {activityReport?.map((data) => (
                    <tr>
                      <td>{data.distributor_id}</td>
                      <td>{data.old_status}</td>
                      <td>{data.changed_status}</td>
                      <td>{data.remarks}</td>
                      <td>{data.user_name}</td>
                      <td>{data.modified_date ? moment(data.modified_date).format("MMMM Do YYYY, h:mm:ss a") : "No data found"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img alt="empty_image" src={MySVG} style={{ opacity: "0.5", width: "10vw" }} />
              <h5 className="mt-3">No results</h5>
            </div>
          </div>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="3" pageTitle="Activity Status" header="Activity Status" breadcrumbs={breadcrumbs}>
      {renderFilters()}
      {renderData()}
    </PageLayout>
  );
}
