import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import WishSimpleCard from "../../components/WishSimpleCard";
import useCommission from "../../services/useCommission";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import WishSelect from "../../components/WishFormComponents/WishSelect";
import WishToaster from "../../components/WishToaster";
import WishFileControl from "../../components/WishFormComponents/WishFileControl";
import SkeletonLoader from "../../components/SkeletonLoader";

export default function UploadDeductions() {
  const location = useLocation();
  const breadcrumbs = [];
  const loggedInUser = JSON.parse(localStorage.getItem("orgUser"));

  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Commissions", linkTo: "/commissionshome" });
  breadcrumbs.push({ title: "Upload Deductions", linkTo: "/uploaddeductions" });

  const { addDeductionBulk, addDeductionSingle, fetchDeductionList } = useCommission();
  const [deductionData, setDeductionData] = useState({
    distributor_id: "",
    week_id: location.state.week,
    amount: "",
    reason: "",
  });
  const [bulkDeductionData, setBulkDeductionData] = useState({
    week_id: location.state.week,
    document: "",
  });
  const [deductionList, setDeductionList] = useState([]);
  const [individualLoading, setIndividualLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [weekToSearch, setWeekToSearch] = useState(location.state.week);

  useEffect(() => {
    getLists();
  }, []);

  const getLists = () => {
    setLoading(true);
    fetchDeductionList(
      { week_id: weekToSearch },
      (data) => {
        setLoading(false);
        setDeductionList(data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleIndividualSubmit = (e) => {
    e.preventDefault();
    if (!deductionData.distributor_id || !deductionData.week_id || !deductionData.amount) {
      WishToaster({ toastMessage: "Please fill in all required fields.", toastType: "error" });
      return;
    }
    setIndividualLoading(true);
    addDeductionSingle(
      deductionData,
      (data) => {
        setIndividualLoading(false);
        WishToaster({ toastMessage: "Success!", toastType: "success" });
      },
      (error) => {
        setIndividualLoading(false);
      }
    );
  };

  const handleBulkSubmit = (e) => {
    e.preventDefault();
    if (!bulkDeductionData.week_id || !bulkDeductionData.document) {
      WishToaster({ toastMessage: "Please fill in all required fields.", toastType: "error" });
      return;
    }
    setBulkLoading(true);
    addDeductionBulk(
      bulkDeductionData,
      (data) => {
        setBulkLoading(false);
        WishToaster({ toastMessage: "Success!", toastType: "success" });
      },
      (error) => {
        setBulkLoading(false);
      }
    );
  };

  return (
    <PageLayout activeSideMenu="5" pageTitle="Upload Deductions" header="Upload Deductions" breadcrumbs={breadcrumbs}>
      <WishSimpleCard>
        <div className="row">
          <div className="col-md-6 mb-4 px-2 left-side">
            <h4 className="mb-2">Add Deductions</h4>
            <WishSingleLineText
              label="Distributor ID"
              onChange={(newValue) => {
                setDeductionData({
                  ...deductionData,
                  distributor_id: newValue,
                });
              }}
            />
            <WishSingleLineText
              label="Week ID"
              readonly={true}
              initialValue={deductionData.week_id}
              //   onChange={(newValue) => {
              //     setDeductionData({
              //       ...deductionData,
              //       week_id: newValue,
              //     });
              //   }}
            />
            <WishSingleLineText
              label="Amount"
              onChange={(newValue) => {
                setDeductionData({
                  ...deductionData,
                  amount: newValue,
                });
              }}
            />
            <WishSingleLineText
              label="Reason"
              onChange={(newValue) => {
                setDeductionData({
                  ...deductionData,
                  reason: newValue,
                });
              }}
            />

            <button className="btn btn-primary float-right" disabled={individualLoading} onClick={handleIndividualSubmit}>
              {individualLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Wait
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <div className="col-md-6 mb-4 px-2">
            <h4 className="mb-2">Bulk Upload Deductions</h4>
            <WishSingleLineText
              label="Week ID"
              readonly={true}
              initialValue={bulkDeductionData.week_id}
              //   onChange={(newValue) => {
              //     setBulkDeductionData({
              //       ...bulkDeductionData,
              //       week_id: newValue,
              //     });
              //   }}
            />
            <WishFileControl
              label="Bulk Upload Deductions"
              filetypes={".xlsx"}
              onRemove={(fileName, fileObject) => {
                setBulkDeductionData({
                  ...bulkDeductionData,
                  document: "",
                });
              }}
              onChange={(fileName, fileObject) => {
                setBulkDeductionData({
                  ...bulkDeductionData,
                  document: fileObject,
                });
              }}
            />
            <div className="mb-3">
              <p>Instructions: Please upload a XLSX file with the following columns: dist_id, Week, Amount, Reason</p>
              <a href={process.env.REACT_APP_BASE_URL + "/compensation/download-adjustment-sample-file"}>Click to download sample file</a>
            </div>
            <button className="btn btn-primary float-right" onClick={handleBulkSubmit} disabled={bulkLoading}>
              {bulkLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Wait
                </>
              ) : (
                "Submit Bulk"
              )}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row mb-2">
              <div className="col">
                <div className="search-bar">
                  <input
                    type="text"
                    className="search-input"
                    value={weekToSearch}
                    placeholder="Enter week..."
                    onChange={(e) => {
                      setWeekToSearch(e.target.value);
                    }}
                  />
                  <button type="button" className="search-icon" onClick={getLists}>
                    <i className="las la la-search"></i>
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <SkeletonLoader rows={6} />
            ) : (
              <>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Distributor ID</th>
                      <th scope="col">Week</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deductionList?.data?.length > 0 ? (
                      deductionList?.data?.map((item) => (
                        <tr>
                          <td>{item.distributor_id}</td>
                          <td>{item.start_week_id}</td>
                          <td>{item.amount}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          {deductionList.message}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </WishSimpleCard>
    </PageLayout>
  );
}
