import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "./AppUtils";

const useCommission = (distributorId) => {
  const { postData, postFormData } = useAPIs();
  const loggedInUser = JSON.parse(localStorage.getItem("orgUser"));

  const fetchCommissionStatus = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      "/compensation/v1/commission-status-for-given-week",
      payload,
      (data, response) => {
        onSuccess && onSuccess(data, response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const fetchButtonStatuses = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      "/compensation/v1/commission-process-button-status-for-given-week",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const initiateStep = (url, payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      url,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const fetchAdjustmentList = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      "/compensation/v1/list-of-adjustments-for-given-week",
      payload,
      (data, response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const fetchAdjustmentTypes = (onSuccess, onError) => {
    postData(
      "/compensation/v1/list-of-adjustment-types",
      {},
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const addAdjustmentSingle = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      "/compensation/v1/add-adjustments-directly",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const addAdjustmentBulk = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postFormData(
      "/compensation/v1/adjustment-file-upload",
      AppUtils.createFormData(payload),
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const uploadBonusStatus = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postFormData(
      "/reports/bonus-payment-status-file-upload",
      AppUtils.createFormData(payload),
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  const fetchDeductionList = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      "/compensation/v1/list-of-deductions-given-week",
      payload,
      (data, response) => {
        onSuccess && onSuccess(response);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const addDeductionSingle = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postData(
      "/compensation/v1/add-deduction-directly",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };
  const addDeductionBulk = (payload, onSuccess, onError) => {
    payload.logged_in_org_user_id = loggedInUser.orguser_id;
    payload.logged_in_org_user_name = loggedInUser.user_name;
    postFormData(
      "/compensation/v1/deduction-file-upload",
      AppUtils.createFormData(payload),
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
        onError && onError(error);
      }
    );
  };

  return {
    fetchButtonStatuses,
    initiateStep,
    fetchCommissionStatus,
    fetchAdjustmentList,
    fetchAdjustmentTypes,
    addAdjustmentSingle,
    addAdjustmentBulk,
    addDeductionBulk,
    addDeductionSingle,
    fetchDeductionList,
    uploadBonusStatus,
  };
};

export default useCommission;
