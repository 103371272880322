import { useState } from "react";
import { useEffect } from "react";
import useLocalStorage from "react-use-localstorage";
import useAPIs from "./useAPIs";

const useMasters = () => {
  const [ranks, setRanks] = useLocalStorage("ranks", null);
  const [ranksList, setRanksList] = useState(null);
  const [mastersError, setError] = useState(null);
  const { apiError, postData, getData } = useAPIs();

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  const [user, setUser] = useLocalStorage("user", null);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    if (user && user !== "") {
      const userFromLocalStorage = JSON.parse(user);
      setLoggedInUser(userFromLocalStorage);
    }

    if (ranks && ranks !== "") {
      const ranksFromLocalStorage = JSON.parse(ranks);

      setRanksList(ranksFromLocalStorage);
    } else {
      getData("/enrollment/bo/fetch-rank-list", (ranksData) => {
        setRanksList(ranksData);
        setRanks(JSON.stringify(ranksData));
      });
    }
  }, []);

  const updateDistributor = (distributorDetails) => {
    console.log(distributorDetails, "distdetails");
    if (distributorDetails) {
      setUser(JSON.stringify(distributorDetails));
      setLoggedInUser(distributorDetails);
    }
  };

  return {
    ranksList,
    mastersError,
    loggedInUser,
    updateDistributor,
  };
};

export default useMasters;
