import _ from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import useAPIs from "./useAPIs";
import WishToaster from "../components/WishToaster";

export const authenticationModel = {
  user_name: Number,
  user_type: (Number, 2),
  password: String,
  device_id: (String, ""),
  device_info: (String, ""),
  device_token: (String, ""),
  mplatform_id: (Number, 2),
  location: (String, ""),
};

export const forgotPasswordModel = {
  user_name: (Number, -1),
  user_type: (Number, -1),
};

const useAuthentication = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { apiError, processing, postData } = useAPIs();
  const loggedInUser = localStorage.getItem("orgUser") && JSON.parse(localStorage.getItem("orgUser"));

  useEffect(() => {
    if (response) {
      //setLoading(false);
    }
  }, [response]);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  const login = (credentials = {}, onSuccess) => {
    const userCredentials = _.cloneDeep(authenticationModel);
    userCredentials.user_name = credentials.user_name;
    userCredentials.password = credentials.password;

    //setLoading(true);
    postData(
      "/auth/login",
      userCredentials,
      (data, response) => {
        setResponse(data);
        console.log(data, response);
        onSuccess(data);
      }
      //setError
    );
  };

  const forgotPassword = (changePasswordDetails, onSuccess) => {
    //setLoading(true);
    postData(
      "/auth/forgot-user-password",
      changePasswordDetails,
      (data) => {
        setResponse(data);
        onSuccess(data);
      }
      //setError
    );
  };

  const logout = (credentials = authenticationModel, onSuccess) => {
    const userCredentials = _.cloneDeep(authenticationModel);
    userCredentials.user_name = credentials.user_name;
    userCredentials.isReadyToAuthenticate = credentials.isReadyToAuthenticate;

    postData("/auth/logout", userCredentials, (data) => {
      setResponse(data);
      localStorage.clear();
      onSuccess(data);
    });
  };
  const sendOTP = (payload, onSuccess, onError) => {
    payload.user_name = loggedInUser.user_name;
    postData(
      "/auth/send-password-reset-otp",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        console.log(error);
        WishToaster({ toastMessage: error, toastType: "error" });
        onError && onError(error);
      }
    );
  };

  const changePassword = (payload, onSuccess) => {
    //setLoading(true);
    postData(
      "/auth/change-user-password",
      payload,
      (data) => {
        setResponse(data);
        onSuccess(data);
      }
      //setError
    );
  };

  return {
    error,
    response,
    login,
    forgotPassword,
    logout,
    processing,
    changePassword,
    sendOTP,
  };
};

export default useAuthentication;
