import useAPIs from "./useAPIs";

const useGenealogyActivities = () => {
  const { postData, getData, postFormData } = useAPIs();

  const IDTransferObject = {
    distributor_id: "",
    mgeneology_activity_id: 1, //1 for id transfer 2 for id swap ,
    week_id: "",
    userid: "",
    distributor_data: {
      id: "",
      display_name: "",
      distributor_name: "",
      pan_no: "",
      gst_no: "",
    },
    coapplicant: {
      distributor_id: "",
      coapplicant_name: "",
      email: "",
      phone: "",
      dob: "",
      coapplicant_pan_no: "",
      mrealtionship: "",
      gender: "",
    },
    distpersonal: {
      id: "",
      distributor_id: "",
      distributor_user_id: "",
      mtitle_id: "",
      mtitle_title: "",
      first_name: "",
      last_name: "",
      email: "",
      dob: "",
      phone: "",
      secondary_phone: "",
      nominee: "",
      mgender_id: "",
      mgender_title: "",
      mlanguage_id: "",
      mlanguage_title: "",
      mmarital_status_id: "",
      mmarital_status_title: "",
      mprofession_id: "",
      mprofession_title: "",
      mmonthly_income_id: "",
      mmonthly_income_title: "",
      nominee_mrelationship_id: "",
      nominee_mrelationship_title: "",
      aadhar_no: "",
      pan_no: "",
      gst_no: "",
      permanent_address_line1: "",
      permanent_address_line2: "",
      permanent_mpostalcode_id: "",
      permanent_mpostalcode: "",
      permanent_mpost_name: "",
      permanent_mcity_id: "",
      permanent_mcity_name: "",
      permanent_mdistrict_id: "",
      permanent_mdistrict_name: "",
      permanent_mstate_id: "",
      permanent_mstate_name: "",
      permanent_mcountry_id: "",
      permanent_mcountry_name: "",
      communication_address_line1: "",
      communication_address_line2: "",
      communication_mpostalcode_id: "",
      communication_mpostalcode: "",
      communication_mpost_name: "",
      communication_mcity_id: "",
      communication_mcity_name: "",
      communication_mdistrict_id: "",
      communication_mdistrict_name: "",
      communication_mstate_id: "",
      communication_mstate_name: "",
      communication_mcountry_id: "",
      communication_mcountry_name: "",
    },
    bankdeatils: {
      distributor_id: "",
      bank_name: "",
      branch_name: "",
      ifsc: "",
      account_no: "",
      account_name: "",
    },
  };

  const getMasters = (onSuccess, onError) => {
    getData(
      "/enrollment/bo/fetch-enrolment-form-master-data",
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getCoAppRelationships = (genderId, maritialStatusId, onSuccess) => {
    getData(`/enrollment/bo/fetch-coapp-relationships-list?gender_id=${genderId}&marital_status_id=${maritialStatusId}`, (data) => {
      onSuccess(data);
    });
  };
  const getCurrentIDdata = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/id-transfer-data",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const updateIDTransferdata = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/update-id-transfer-data",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  return { IDTransferObject, getCurrentIDdata, getMasters, updateIDTransferdata, getCoAppRelationships };
};

export default useGenealogyActivities;
