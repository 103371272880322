import React, { useEffect, useState } from "react";
import _ from "lodash";
import PageLayout from "../../../../components/PageLayout";
import WishSimpleCard from "../../../../components/WishSimpleCard";
import WishSingleLineText from "../../../../components/WishFormComponents/WishSingleLineText";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import MySVG from "../../../../assets/app-assets/images/svg/undraw_no_data_re_kwbl.svg";
import useGenealogyActivities from "../../../../services/useGenealogyActivities";
import BasicDetailsIDTransfer from "./BasicDetailsIDTransfer";
import BankDetailsIDTransfer from "./BankDetailsIDTransfer";
import ContactDetailsIDTransfer from "./ContactDetailsIDTransfer";
import CoApplicantDetailsIDTransfer from "./CoapplicantDetailsIDTransfer";
import WishToaster from "../../../../components/WishToaster";

export default function IdTransfer() {
  const { IDTransferObject, getCurrentIDdata, getMasters, updateIDTransferdata, getCoAppRelationships } = useGenealogyActivities();

  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [masters, setMasters] = useState({});
  const [fetchPayload, setFetchPayload] = useState({ distributor_id: "" });
  const [IDTransferPayload, setIDTransferPayload] = useState({});

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Genealogy Activities", linkTo: "/genealogyhome" });
  breadcrumbs.push({ title: "ID Transfer", linkTo: "/idtransfer" });

  useEffect(() => {
    getMasters((data) => {
      setMasters(data);
    });
  }, []);

  const getLists = () => {
    setLoading(true);
    getCurrentIDdata(
      fetchPayload,
      (data) => {
        setIDTransferPayload(data);
        setLoading(false);
      },
      (error) => {
        setIDTransferPayload({});
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    // console.clear();
    console.log(IDTransferPayload);
  }, [IDTransferPayload]);

  const handleIDTransfer = () => {
    console.log(IDTransferPayload);
    IDTransferPayload.distributor_id = fetchPayload.distributor_id;
    IDTransferPayload.old_dist_id = null;
    IDTransferPayload.mgeneology_activity_id = 1; //1 for id transfer 2 for id swap ,
    IDTransferPayload.userid = JSON.parse(localStorage.getItem("user"))?.user_id;
    IDTransferPayload.bankdeatils.distributor_id = fetchPayload.distributor_id;
    IDTransferPayload.coapplicant.distributor_id = fetchPayload.distributor_id;
    IDTransferPayload.distributor_data.id = fetchPayload.distributor_id;
    IDTransferPayload.distpersonal.distributor_id = fetchPayload.distributor_id;

    setBtnLoading(true);
    updateIDTransferdata(
      IDTransferPayload,
      (data) => {
        console.log(data);
        setBtnLoading(false);
        WishToaster({ toastType: "success", toastTitle: "Success!", toastMessage: "ID Transfer success." });
      },
      (error) => {
        console.log(error);
        setBtnLoading(false);
      }
    );
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-filter"></i> Filters
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Distributor ID :</label>
              <WishSingleLineText
                placeholder={"Enter distributor ID"}
                initialValue={fetchPayload.distributor_id}
                onChange={(value) => {
                  setFetchPayload((prevState) => ({ ...prevState, distributor_id: value }));
                }}
              />
            </div>
            <div className="col-12 pb-2">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                onClick={(e) => {
                  getLists();
                }}
              >
                Filter
              </button>
              <button
                className="btn btn-sm btn-secondary align-self-start mt-1"
                onClick={(e) => {
                  setFetchPayload({
                    distributor_id: "",
                  });
                }}
              >
                Clear
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  const renderData = function () {
    return (
      <WishSimpleCard>
        {loading ? (
          <>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <SkeletonLoader rows={12} />
            </div>
          </>
        ) : IDTransferPayload?.distpersonal?.distributor_id ? (
          <div className="row">
            <BasicDetailsIDTransfer enrollmentMasterData={masters} IDTransferPayload={IDTransferPayload} setIDTransferPayload={setIDTransferPayload} />
            <ContactDetailsIDTransfer enrollmentMasterData={masters} IDTransferPayload={IDTransferPayload} setIDTransferPayload={setIDTransferPayload} />
            <BankDetailsIDTransfer enrollmentMasterData={masters} IDTransferPayload={IDTransferPayload} setIDTransferPayload={setIDTransferPayload} />
            <CoApplicantDetailsIDTransfer enrollmentMasterData={masters} IDTransferPayload={IDTransferPayload} setIDTransferPayload={setIDTransferPayload} />
            <div className="col-12 text-right">
              <button className="btn btn-primary mt-1" onClick={handleIDTransfer} disabled={btnLoading}>
                {btnLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Wait
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <img alt="empty_image" src={MySVG} style={{ opacity: "0.5", width: "10vw" }} />
              <h5 className="mt-3">No results</h5>
            </div>
          </div>
        )}
      </WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="3" pageTitle="ID Transfer" header="ID Transfer" breadcrumbs={breadcrumbs}>
      {renderFilters()}
      {renderData()}
    </PageLayout>
  );
}
