import React, { useEffect, useState } from "react";
import data from "../../data/Data.json";
import WishSimpleCard from "../../components/WishSimpleCard";
import useConfig from "../../services/useConfig";
import PageLayout from "../../components/PageLayout";
import SkeletonLoader from "../../components/SkeletonLoader";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import WishToaster from "../../components/WishToaster";
import WishModal from "../../components/WishModal";
import { Link } from "react-router-dom";
import { AppUtils } from "../../services/AppUtils";
import WishSelect from "../../components/WishFormComponents/WishSelect";

export default function Users() {
  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Configurations", linkTo: "/confighome" });
  breadcrumbs.push({ title: "Users", linkTo: "/usermanagement" });
  const options = { day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true };

  const { createRole, getUsersRoutesRoles, assignRoleToUser, createUser } = useConfig();
  const [masters, setMasters] = useState({});
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    orguser_id: JSON.parse(localStorage.getItem("orgUser"))?.orguser_id,
    orguser_name: JSON.parse(localStorage.getItem("orgUser"))?.user_name,
    user_type: 2,
    user_name: "",
    email: "",
    role_id: "",
    password: "",
  });
  const [assignPayload, setAssignPayload] = useState({ user_id: "", role_id: "" });

  useEffect(() => {
    getLists();
  }, []);

  const getLists = () => {
    setLoading(true);
    getUsersRoutesRoles(
      {},
      (data) => {
        setMasters(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const assignRoles = () => {
    setLoading(true);
    assignRoleToUser(
      assignPayload,
      (data) => {
        getLists(data);
        AppUtils.hideDialog("dlgAssignRoles");
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const renderData = (row, head) => {
    switch (head?.type) {
      case "date":
        return row[head?.key] ? new Date(row[head?.key]).toLocaleString("en-IN", options) : "No data found";
      case "status":
        return row[head?.key] ? "Active" : "Inactive";
      default:
        return row[head?.key];
    }
  };

  const Table = ({ data, heads }) => {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              {heads?.map((head) => (
                <th>{head.title}</th>
              ))}
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr>
                {heads.map((head) => (
                  <td>{renderData(row, head)}</td>
                ))}
                <td>
                  <Link to={""} data-toggle="modal" data-target="#dlgAssignRoles">
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) => {
                        setAssignPayload({ ...assignPayload, role_id: row.role_id, user_id: row.id });
                      }}
                    >
                      Edit role
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderFilters = function () {
    return (
      <WishSimpleCard
        body={
          <div className="row">
            <div className="col-12 pb-2">
              <h5>
                <i className="las la-plus"></i> Add new user
              </h5>
            </div>
            <div className="col-sm-3">
              <label>Name :</label>
              <WishSingleLineText
                placeholder={"Enter role name"}
                onChange={(newValue) => {
                  setPayload({ ...payload, user_name: newValue });
                }}
              />
            </div>
            <div className="col-sm-3">
              <label>Role :</label>
              <WishSelect
                data={masters?.roles}
                dataKey="role_id"
                dataValue="role_name"
                placeholder={true}
                onSelect={(newValue) => {
                  setPayload({ ...payload, role_id: newValue });
                }}
              />
            </div>
            <div className="col-sm-3">
              <label>E-mail :</label>
              <WishSingleLineText
                placeholder={"Enter email"}
                onChange={(newValue) => {
                  setPayload({ ...payload, email: newValue });
                }}
              />
            </div>
            <div className="col-sm-3">
              <label>Password :</label>
              <WishSingleLineText
                placeholder={"Enter password"}
                onChange={(newValue) => {
                  setPayload({ ...payload, password: newValue });
                }}
              />
            </div>
            <div className="col-sm-12">
              <button
                className="btn btn-sm btn-primary align-self-start mr-1 mt-1"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setLoading(true);
                  createUser(
                    payload,
                    (data) => {
                      getLists();
                      WishToaster({ toastType: "success", toastMessage: "Successfully created user!" });
                    },
                    (error) => {
                      setLoading(false);
                    }
                  );
                }}
              >
                Create
              </button>
            </div>
          </div>
        }
      ></WishSimpleCard>
    );
  };

  return (
    <PageLayout activeSideMenu="9" pageTitle="User Management" header={`User Management`} breadcrumbs={breadcrumbs}>
      {renderFilters()}
      <WishSimpleCard>{loading ? <SkeletonLoader rows={12} /> : <Table data={masters?.users} heads={data?.users} />}</WishSimpleCard>
      <WishModal id="dlgAssignRoles" title="Edit Role" hideCancelButton={true} noFooter={true} finishTitle="Proceed" modalSize="modal-lg">
        <div className="form-group row">
          <label htmlFor="ddRole" className="col-sm-3 col-form-label text-align-end">
            Role
          </label>
          <div className="col-sm-9">
            <select
              id="ddRole"
              name="ddRole"
              className="custom-select"
              required="required"
              value={assignPayload.role_id}
              onChange={(e) => {
                setAssignPayload({ ...assignPayload, role_id: e.target.value });
              }}
            >
              <option value="">Choose...</option>
              {masters?.roles &&
                masters?.roles?.map((role) => {
                  return <option value={role.role_id}>{role.role_name}</option>;
                })}
            </select>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light ml-auto float-right"
          disabled={loading}
          onClick={(e) => {
            assignRoles();
          }}
        >
          {loading ? (
            <>
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <span>Assigning...</span>
            </>
          ) : (
            <>
              <span>Assign role</span>
            </>
          )}
        </button>
      </WishModal>
    </PageLayout>
  );
}
