import React from "react";
import PageLayout from "../../../components/PageLayout";
import WishCard from "../../../components/WishCard";

export default function DistConfigHome() {
  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Distributor", linkTo: "/distributor" });
  breadcrumbs.push({ title: "Distributor Configuration", linkTo: "/distconfig" });

  const data = [
    {
      title: "Distributor Activity Status",
      icon: "las la-file-alt",
      line1: "Generate a report to view insurance-related information and statistics.",
      linkTo: "/distupdatestatus",
    },
    {
      title: "CFL/CFR Adjustment",
      icon: "las la-user-check",
      line1: "Identify distributors who are eligible for insurance coverage based on predefined criteria.",
      linkTo: "/distupdatecflcfr",
    },
    {
      title: "PV Limit Adjustment",
      icon: "las la-cloud-upload-alt",
      line1: "Upload insurance-related documents and records for distributors within your organization.",
      linkTo: "/distupdatepv",
    },
    {
      title: "Rank Adjustment",
      icon: "las la-cloud-upload-alt",
      line1: "Upload insurance-related documents and records for distributors within your organization.",
      linkTo: "/distrankadjustment",
    },
    {
      title: "Password Reset",
      icon: "las la-lock",
      line1: "Upload insurance-related documents and records for distributors within your organization.",
      linkTo: "/distpasswordreset",
    },
  ];

  return (
    <PageLayout activeSideMenu="3" pageTitle="Rewards" header="Rewards" breadcrumbs={breadcrumbs}>
      <section className="row">
        {data.map((item) => (
          <div className="col-md-4 col-sm-6">
            <WishCard maxHeight={true} linkToState={item.linkToState} title={item.title} icon={item.icon} line1={item.line1} line2={item.line2} linkTo={item.linkTo}></WishCard>
          </div>
        ))}
      </section>
    </PageLayout>
  );
}
