import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import WishSimpleCard from "../../components/WishSimpleCard";
import useCommission from "../../services/useCommission";
import WishSingleLineText from "../../components/WishFormComponents/WishSingleLineText";
import WishSelect from "../../components/WishFormComponents/WishSelect";
import WishToaster from "../../components/WishToaster";
import WishFileControl from "../../components/WishFormComponents/WishFileControl";
import SkeletonLoader from "../../components/SkeletonLoader";

export default function UploadAdjustments() {
  const location = useLocation();
  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Commissions", linkTo: "/commissionshome" });
  breadcrumbs.push({ title: "Upload adjustments", linkTo: "/uploadadjustments" });

  const { fetchAdjustmentList, fetchAdjustmentTypes, addAdjustmentSingle, addAdjustmentBulk } = useCommission();
  const [adjustmentData, setAdjustmentData] = useState({
    distributor_id: "",
    week_id: location.state.week,
    adjustment_type_id: "",
    amount: "",
    remarks: "",
  });
  const [bulkAdjustmentData, setBulkAdjustmentData] = useState({
    week_id: location.state.week,
    document: "",
  });
  const [adjustmentTypes, setAdjustmentTypes] = useState([]);
  const [adjustmentList, setAdjustmentList] = useState([]);
  const [individualLoading, setIndividualLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [weekToSearch, setWeekToSearch] = useState(location.state.week);

  useEffect(() => {
    fetchAdjustmentTypes(
      (data) => {
        setAdjustmentTypes(data);
      },
      (error) => {}
    );
    getLists();
  }, []);

  const getLists = () => {
    setLoading(true);
    fetchAdjustmentList(
      { week_id: weekToSearch },
      (data) => {
        setLoading(false);
        setAdjustmentList(data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleIndividualSubmit = (e) => {
    e.preventDefault();
    if (!adjustmentData.distributor_id || !adjustmentData.week_id || !adjustmentData.adjustment_type_id || !adjustmentData.amount) {
      WishToaster({ toastMessage: "Please fill in all required fields.", toastType: "error" });
      return;
    }
    setIndividualLoading(true);
    addAdjustmentSingle(
      adjustmentData,
      (data) => {
        setIndividualLoading(false);
        WishToaster({ toastMessage: "Success!", toastType: "success" });
      },
      (error) => {
        setIndividualLoading(false);
      }
    );
  };

  const handleBulkSubmit = (e) => {
    e.preventDefault();
    if (!bulkAdjustmentData.week_id || !bulkAdjustmentData.document) {
      WishToaster({ toastMessage: "Please fill in all required fields.", toastType: "error" });
      return;
    }
    setBulkLoading(true);
    addAdjustmentBulk(
      bulkAdjustmentData,
      (data) => {
        setBulkLoading(false);
        WishToaster({ toastMessage: "Success!", toastType: "success" });
      },
      (error) => {
        setBulkLoading(false);
      }
    );
  };

  return (
    <PageLayout activeSideMenu="5" pageTitle="Upload Adjustments" header="Upload Adjustments" breadcrumbs={breadcrumbs}>
      <WishSimpleCard>
        <div className="row">
          <div className="col-md-6 mb-4 px-2 left-side">
            <h4 className="mb-2">Add Adjustments</h4>
            <WishSelect
              data={adjustmentTypes}
              label="Adjustment type"
              dataValue="title"
              placeholder={true}
              onSelect={(newValue) => {
                setAdjustmentData({
                  ...adjustmentData,
                  adjustment_type_id: newValue,
                });
              }}
            />
            <WishSingleLineText
              label="Distributor ID"
              onChange={(newValue) => {
                setAdjustmentData({
                  ...adjustmentData,
                  distributor_id: newValue,
                });
              }}
            />
            <WishSingleLineText
              label="Week ID"
              readonly={true}
              initialValue={adjustmentData.week_id}
              // onChange={(newValue) => {
              //   setAdjustmentData({
              //     ...adjustmentData,
              //     week_id: newValue,
              //   });
              // }}
            />
            <WishSingleLineText
              label="Amount"
              onChange={(newValue) => {
                setAdjustmentData({
                  ...adjustmentData,
                  amount: newValue,
                });
              }}
            />
            <WishSingleLineText
              label="Remarks"
              onChange={(newValue) => {
                setAdjustmentData({
                  ...adjustmentData,
                  remarks: newValue,
                });
              }}
            />
            <button className="btn btn-primary float-right" disabled={individualLoading} onClick={handleIndividualSubmit}>
              {individualLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Wait
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <div className="col-md-6 mb-4 px-2">
            <h4 className="mb-2">Bulk Upload Adjustments</h4>
            <WishSingleLineText
              label="Week ID"
              initialValue={bulkAdjustmentData.week_id}
              readonly={true}
              // onChange={(newValue) => {
              //   setBulkAdjustmentData({
              //     ...bulkAdjustmentData,
              //     week_id: newValue,
              //   });
              // }}
            />
            <WishFileControl
              label="Bulk Upload Adjustments"
              filetypes={".xlsx"}
              onRemove={(fileName, fileObject) => {
                setBulkAdjustmentData({
                  ...bulkAdjustmentData,
                  document: "",
                });
              }}
              onChange={(fileName, fileObject) => {
                setBulkAdjustmentData({
                  ...bulkAdjustmentData,
                  document: fileObject,
                });
              }}
            />
            <div className="mb-3">
              <p>Instructions: Please upload a XLSX file with the following columns: dist_id, Week, Amount, Reason</p>
              <a href={process.env.REACT_APP_BASE_URL + "/compensation/download-adjustment-sample-file"}>Click to download sample file</a>
            </div>
            <button className="btn btn-primary float-right" onClick={handleBulkSubmit} disabled={bulkLoading}>
              {bulkLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Wait
                </>
              ) : (
                "Submit Bulk"
              )}
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row mb-2">
              <div className="col">
                <div className="search-bar">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Enter week..."
                    value={weekToSearch}
                    onChange={(e) => {
                      setWeekToSearch(e.target.value);
                    }}
                  />
                  <button type="button" className="search-icon" onClick={getLists}>
                    <i className="las la la-search"></i>
                  </button>
                </div>
              </div>
            </div>
            {loading ? (
              <SkeletonLoader rows={6} />
            ) : (
              <>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Distributor ID</th>
                      <th scope="col">Week</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Adjustment type</th>
                      <th scope="col">Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adjustmentList?.data?.length > 0 ? (
                      adjustmentList?.data?.map((item) => (
                        <tr>
                          <td>{item.distributor_id}</td>
                          <td>{item.week}</td>
                          <td>{item.amount}</td>
                          <td>{item.adjustment_type}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No data found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </WishSimpleCard>
    </PageLayout>
  );
}
