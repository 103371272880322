import useAPIs from "./useAPIs";

const useDistConfig = () => {
  const { postData, getData, postFormData } = useAPIs();
  const loggedInUser = JSON.parse(localStorage.getItem("orgUser"));

  const getDistActiveData = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/bo/getDistActiveData",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getActivityReport = (payload, onSuccess, onError) => {
    postData(
      "/enrollment/bo/getActivityReport",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const updateDistActiveData = (payload, onSuccess, onError) => {
    payload.userid = loggedInUser.orguser_id;
    postData(
      "/enrollment/bo/updateDistActiveData",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  const getCFData = (payload, onSuccess, onError) => {
    postData(
      "/compensation/bo/getCFData",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const updateCFData = (payload, onSuccess, onError) => {
    payload.userid = loggedInUser.orguser_id;
    postData(
      "/compensation/bo/updateCFData",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  const getPV = (payload, onSuccess, onError) => {
    postData(
      "/sales/v1/bo/adjustPv",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const updatePV = (payload, onSuccess, onError) => {
    payload.userid = loggedInUser.orguser_id;
    postData(
      "/sales/v1/bo/updatePv",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const getCurrentRank = (payload, onSuccess, onError) => {
    postData(
      "/compensation/bo/getCurrentRankData",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const updateRank = (payload, onSuccess, onError) => {
    payload.userid = loggedInUser.orguser_id;
    postData(
      "/compensation/bo/updateRankData",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const changeDistPassword = (payload, onSuccess, onError) => {
    payload.userid = loggedInUser.orguser_id;
    postData(
      "/auth/change-user-password-bo",
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };

  return {
    getDistActiveData,
    updateDistActiveData,
    getActivityReport,
    getCFData,
    updateCFData,
    getPV,
    updatePV,
    getCurrentRank,
    updateRank,
    changeDistPassword,
  };
};

export default useDistConfig;
